import type { BusinessLinePageData } from './useBusinessLinePageData.types';
import type { BusinessLineEditHandlers } from './useBusinessLinePageHandlers.types';

import { useBusinessLinesReduxActions } from 'shared/features/businessLines/hooks';
import { useCallback } from 'react';

export const useBusinessLinePageHandlers = ({
  formattedData
}: {
  formattedData: BusinessLinePageData['formattedData'];
}): BusinessLineEditHandlers => {
  const businessLinesReduxActions = useBusinessLinesReduxActions();

  const handleLoadBusinessLine: BusinessLineEditHandlers['handleLoadBusinessLine'] =
    useCallback(() => {
      businessLinesReduxActions.clearBusinessLines();
      if (!isNaN(Number(formattedData.businessLineId))) {
        businessLinesReduxActions.fetchBusinessLine({ id: formattedData.businessLineId });
      }
    }, [formattedData.businessLineId, businessLinesReduxActions]);

  const handleUpdateBusinessLineStatus: BusinessLineEditHandlers['handleUpdateBusinessLineStatus'] =
    isActive => {
      businessLinesReduxActions.updateBusinessLine({
        id: formattedData.businessLineId,
        active: isActive
      });
    };

  return {
    handleLoadBusinessLine: handleLoadBusinessLine,
    handleUpdateBusinessLineStatus: handleUpdateBusinessLineStatus
  };
};
