import type * as uiLib from '@compliance.ai/web-components';
import type { DocumentDetailsFieldsHandlers } from './useDocumentDetailsFieldsHandlers.types';
import type { DocumentDetailsTabsData } from '../../../hooks/useDocumentDetailsTabsData.types';

import { DOCUMENT_GENERAL } from '../../../../../DocumentDetails.constants';

export const useDocumentDetailsFieldsHandlers = ({
  docDetails
}: {
  docDetails: DocumentDetailsTabsData['localState']['docDetails'];
}): DocumentDetailsFieldsHandlers => {
  const handleCopyCitations: DocumentDetailsFieldsHandlers['handleCopyCitations'] = () => {
    const text = (docDetails?.[DOCUMENT_GENERAL.citationIds] as [])
      ?.map((item: uiLib.SelectOption) => item?.label)
      .join(', ');
    navigator.clipboard.writeText(text);
  };

  return {
    handleCopyCitations
  };
};
