// library pdfobject don't have type declaration why we use next two lines
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PDFobjecct from 'pdfobject';
import { useCallback } from 'react';
import { DOCUMENT_GENERAL } from '../DocumentDetails.constants';

import type { DocumentDetailsData } from './useDocumentDetailsData.types';
import type {
  DocumentDetailsHandlers,
  HandlerActionsParams
} from './useDocumentDetailsHandlers.types';

export const useDocumentDetailsHandlers = ({
  localActions,
  formattedData
}: {
  localActions: DocumentDetailsData['localActions'];
  formattedData: DocumentDetailsData['formattedData'];
}): DocumentDetailsHandlers => {
  const handleTakeAction = (params: HandlerActionsParams) => {
    localActions?.setFlagAction(params[DOCUMENT_GENERAL.pipelineStatus]);
  };

  const handleHideModal = () => {
    localActions?.setFlagAction('');
  };

  const handleRenderPdf = useCallback(() => {
    if (formattedData.pdfUrl) {
      PDFobjecct.embed(String(formattedData.pdfUrl), 'div#document-text');
    }
  }, [formattedData.pdfUrl]);

  return {
    handleTakeAction,
    handleHideModal,
    handleRenderPdf
  };
};
