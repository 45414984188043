import type { BusinessLineStatusListConfig } from './useBusinessLineStatusListConfig.types';

import * as constants from '../BusinessLineStatus.constants';
import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useBusinessLineStatusListConfig = (): BusinessLineStatusListConfig => {
  const options: BusinessLineStatusListConfig['options'] = useMemo(
    () => [
      {
        label: (
          <div className="sources_status_status-block_option">
            <uiLib.IconCheck />
            <uiLib.Typography>Activate</uiLib.Typography>
          </div>
        ),
        value: constants.BUSINESS_LINE_CREATION_VALUES.ACTIVE
      },
      {
        label: (
          <div className="sources_status_status-block_option">
            <uiLib.IconWarning />
            <uiLib.Typography>Deactivate</uiLib.Typography>
          </div>
        ),
        value: constants.BUSINESS_LINE_CREATION_VALUES.DEACTIVATED
      }
    ],
    []
  );

  return {
    options
  };
};
