import { setNotification, NOTIFICATION_TYPES } from 'admin/components/Notification';
import {
  api_getBusinessLines,
  api_putBusinessLines,
  api_postBusinessLines,
  api_deleteBusinessLines
} from './businessLines.api';
import {
  startLoad,
  finishLoad,
  setBusinessLine,
  createBusinessLine,
  updateBusinessLine,
  deleteBusinessLine
} from './businessLines.reducer';
import { initialState } from './businessLines.reducer';

const SUCCESS = {
  BusinessLineCreated: 'Business Line was successfully created',
  BusinessLineUpdated: 'Business Line was successfully updated',
  BusinessLineDeleted: 'Business Line was successfully deleted'
};

export const getBusinessLines = params => dispatch => {
  dispatch(startLoad());
  return api_getBusinessLines(params)
    .then(res => {
      dispatch(setBusinessLine(res));
      dispatch(finishLoad());
    })
    .catch(res => dispatch(finishLoad()));
};

export const createBusinessLines = params => dispatch => {
  dispatch(startLoad());
  return api_postBusinessLines(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.productServiceCreated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(createBusinessLine(res));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const updateBusinessLines = params => dispatch => {
  dispatch(startLoad());
  return api_putBusinessLines(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.productServiceUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(updateBusinessLine(res));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const removeBusinessLines = params => dispatch => {
  dispatch(startLoad());
  return api_deleteBusinessLines(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.BusinessLineDeleted, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(deleteBusinessLine(params));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const clearBusinessLines = () => dispatch => {
  dispatch(finishLoad());
  return dispatch(setBusinessLine(initialState));
};
