import type { FC } from 'react';
import type { BusinessLineStatusProps } from './BusinessLineStatus.types';
import type { BusinessLineFromResponse } from 'shared/features/businessLines/businessLines.types';

import Badge from 'admin/components/Badge';
import { getBusinessLineStatusBadgeInfo } from './BusinessLineStatus.helpers';

export const BusinessLineStatus: FC<BusinessLineStatusProps> = ({ row }) => {
  const businessLineValue = row as BusinessLineFromResponse;
  const badgeInfo = getBusinessLineStatusBadgeInfo(businessLineValue?.is_active);
  return (
    <Badge type={badgeInfo.type} size="medium" title={badgeInfo.text}>
      {badgeInfo.text}
    </Badge>
  );
};
