import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PriorityHighIcon from '@material-ui/icons/InfoOutlined';
import TextInput from '../../../../../../../components/TextInput';
import Textarea from '../../../../../../../components/Textarea';
import Select from '../../../../../../../components/ReactSelect/Select';
import AsyncSelect from '../../../../../../../components/ReactSelect/SelectAsync';
import CustomDatePicker from '../../../../../../../components/CustomDatePicker';
import Tooltip from '../../../../../../../components/Tooltip';
import { DOCUMENT_GENERAL } from '../../../../DocumentDetails.constants';
import {
  loadScrapedCategoryOptions,
  loadJurisdictionsOptions,
  loadNewsSourceOptions,
  loadAgencyOptions,
  loadBooleanOptions
} from '../../DocumentDetails.helper';
import Typography from '../../../../../../../components/Typography';
import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../../DocumentDetails.contstants';
import { useDocumentDetailsFieldsData } from './hooks/useDocumentDetailsFieldsData';
import { useEitlLabelsAsyncSelectOptions } from './hooks/useEitlLabelsAsyncSelectOptions';
import { useDocumentDetailsFieldsMutations } from './hooks/useDocumentDetailsFieldsMutations';
import { useDocumentDetailsFieldsHandlers } from './hooks/useDocumentDetailsFieldsHandlers';

const DocumentDetailsFields = ({
  editMode,
  docDetails,
  errorFields,
  handleChanges,
  handleChangesEvent,
  action
}) => {
  const { formattedData } = useDocumentDetailsFieldsData({ editMode, docDetails });
  const {
    handlers: { handleFetch }
  } = useEitlLabelsAsyncSelectOptions();
  const handlers = useDocumentDetailsFieldsHandlers({ docDetails });
  const { createEitlLabelsDataMutation } = useDocumentDetailsFieldsMutations({
    onChanges: handleChanges,
    docDetails
  });
  return (
    <div className="document-details_fields">
      <Tooltip
        label={formattedData?.label}
        className="input_1"
        fullDisable={!formattedData?.extraInfo || !formattedData?.disableEdit}
        floatTooltip
        classNameTooltipID="global-tooltip"
      >
        <Select
          className="input_1"
          selectClassName={cn(
            'document-details_fields_status-select',
            formattedData?.getStatusStyle
          )}
          label="Pipeline InteractionStatus"
          name={DOCUMENT_GENERAL.pipelineStatus}
          value={docDetails[DOCUMENT_GENERAL.pipelineStatus]}
          options={formattedData?.getStatusOptions}
          isDisabled={formattedData?.disableEdit}
          onChange={e => handleChanges(DOCUMENT_GENERAL.pipelineStatus, e)}
          isSearchable={false}
        />
        {formattedData?.extraInfo && formattedData?.disableEdit && (
          <PriorityHighIcon className="info-icon-doc" />
        )}
      </Tooltip>
      <Select
        className="input_1"
        label="Take action"
        name={DOCUMENT_GENERAL.takeAction}
        value={action}
        options={formattedData?.getActionOptions}
        isDisabled={!!editMode}
        placeholder={'Select action'}
        onChange={e => handleChanges(DOCUMENT_GENERAL.takeAction, e)}
        isSearchable={false}
      />
      <uiLib.Tooltip title={docDetails[DOCUMENT_GENERAL.officialId]}>
        <uiLib.TextField
          classNames={{
            labeledFieldContainer: 'input_1'
          }}
          label="Official ID"
          value={docDetails[DOCUMENT_GENERAL.officialId]}
          isDisabled
        />
      </uiLib.Tooltip>
      <uiLib.Tooltip title={docDetails[DOCUMENT_GENERAL.docketId]}>
        <uiLib.TextField
          classNames={{
            labeledFieldContainer: 'input_1'
          }}
          label="Docket ID"
          value={docDetails[DOCUMENT_GENERAL.docketId]}
          isDisabled
        />
      </uiLib.Tooltip>
      <Textarea
        className="input_2"
        label="Title"
        name={DOCUMENT_GENERAL.title}
        value={docDetails[DOCUMENT_GENERAL.title]}
        isDisabled={formattedData?.disableEdit}
        onChange={handleChangesEvent}
      />
      <CustomDatePicker
        className="input_3"
        label="Pub Date"
        name={DOCUMENT_GENERAL.publicationDate}
        selected={docDetails[DOCUMENT_GENERAL.publicationDate]}
        isDisabled={formattedData?.disableEdit}
        onChange={date => handleChanges(DOCUMENT_GENERAL.publicationDate, date)}
        isError={errorFields.includes(DOCUMENT_GENERAL.publicationDate)}
      />
      <CustomDatePicker
        className="input_3"
        label="Updated Date"
        name={DOCUMENT_GENERAL.updatedAt}
        selected={docDetails[DOCUMENT_GENERAL.updatedAt]}
        isDisabled={formattedData?.disableEdit}
        onChange={date => handleChanges(DOCUMENT_GENERAL.updatedAt, date)}
      />
      <uiLib.LabeledField label={'Effective Date'} className="input_3">
        <uiLib.DatePicker
          value={docDetails[DOCUMENT_GENERAL.effectiveOn]}
          isDisabled
          format={uiLib.getLocalDateFormat()}
        />
      </uiLib.LabeledField>
      <Textarea
        className="input_2"
        label="PDF URL"
        name={DOCUMENT_GENERAL.pdfUrl}
        value={docDetails[DOCUMENT_GENERAL.pdfUrl]}
        isDisabled={formattedData?.disableEdit}
        isLabelLink
        onChange={handleChangesEvent}
      />
      <Textarea
        className="input_2"
        label="WEB URL"
        name={DOCUMENT_GENERAL.webUrl}
        value={docDetails[DOCUMENT_GENERAL.webUrl]}
        isDisabled={formattedData?.disableEdit}
        isLabelLink
        onChange={handleChangesEvent}
      />
      <Textarea
        className="input_2"
        label="C.ai URL"
        name={DOCUMENT_GENERAL.aiUrl}
        value={docDetails[DOCUMENT_GENERAL.aiUrl]}
        isDisabled
        isLabelLink
        onChange={() => {}}
      />
      <AsyncSelect
        className="input_1"
        label="Scraped Category"
        isDisabled={formattedData?.disableEdit}
        value={docDetails[DOCUMENT_GENERAL.scrapedCategory]}
        onChange={e => handleChanges(DOCUMENT_GENERAL.scrapedCategory, e)}
        loadOptions={value => loadScrapedCategoryOptions('name', value, 'name')}
        isClearable
      />
      <AsyncSelect
        className="input_1"
        label="Jurisdictions"
        isDisabled={formattedData?.disableEdit}
        value={docDetails[DOCUMENT_GENERAL.jurisdiction]}
        onChange={e => handleChanges(DOCUMENT_GENERAL.jurisdiction, e)}
        loadOptions={value => loadJurisdictionsOptions('short_name', value, 'short_name')}
        isClearable
      />
      <uiLib.LabeledField label="Has unofficial publication date" className="input_1">
        <uiLib.Select
          type={uiLib.SELECT_TYPES.DEFAULT}
          isDisabled={formattedData?.disableEdit}
          isClearable
          value={docDetails[DOCUMENT_GENERAL.hasUnofficialPublicationDate]}
          options={constants.SELECT_OPTIONS}
          onChange={e => handleChanges(DOCUMENT_GENERAL.hasUnofficialPublicationDate, e)}
        />
      </uiLib.LabeledField>
      <AsyncSelect
        className="input_1"
        label="News Source"
        // only agency or news_source should be active
        isDisabled={
          formattedData?.disableEdit || _.get(docDetails, [DOCUMENT_GENERAL.agencies, 'length'])
        }
        value={docDetails[DOCUMENT_GENERAL.newsSource]}
        onChange={e => handleChanges(DOCUMENT_GENERAL.newsSource, e)}
        loadOptions={value => loadNewsSourceOptions('name', value, 'name')}
        isClearable
      />
      <AsyncSelect
        className="input_1"
        label="Agencies"
        // only agency or news_source should be active
        isDisabled={
          formattedData?.disableEdit ||
          // resolve situation when doc have agency and news_source at the same time
          (_.get(docDetails, [DOCUMENT_GENERAL.newsSource, 'value']) &&
            !_.get(docDetails, [DOCUMENT_GENERAL.agencies, 'length']))
        }
        value={docDetails[DOCUMENT_GENERAL.agencies]}
        onChange={e => handleChanges(DOCUMENT_GENERAL.agencies, e)}
        loadOptions={value => loadAgencyOptions('short_name', value, 'short_name')}
        isMulti
        isClearable
      />
      <TextInput
        className="input_1"
        label="Parent ID"
        name={DOCUMENT_GENERAL.parentId}
        value={docDetails[DOCUMENT_GENERAL.parentId]}
        isDisabled={formattedData?.disableEdit}
        onChange={handleChangesEvent}
      />
      <Select
        className="input_1"
        selectClassName={cn(
          'document-details_fields_status-select',
          formattedData?.getParentStatusStyle
        )}
        label="Pipeline InteractionStatus of Parent Document"
        placeholder="n/a"
        name={DOCUMENT_GENERAL.parentStatus}
        value={docDetails[DOCUMENT_GENERAL.parentStatus]}
        isDisabled
        onChange={() => {}}
      />
      <TextInput
        className="input_1"
        label="Duplicate"
        name={DOCUMENT_GENERAL.duplicateList}
        value={docDetails[DOCUMENT_GENERAL.duplicateList]}
        isDisabled
        onChange={handleChangesEvent}
      />
      <TextInput
        className="input_1"
        label="Potential Duplicate"
        name={DOCUMENT_GENERAL.potentialDuplicateList}
        value={docDetails[DOCUMENT_GENERAL.potentialDuplicateList]}
        isDisabled
        onChange={handleChangesEvent}
      />
      <uiLib.Tooltip title={docDetails[DOCUMENT_GENERAL.shelfId]}>
        <uiLib.TextField
          classNames={{
            labeledFieldContainer: 'input_1'
          }}
          label="Shelf Id"
          value={docDetails[DOCUMENT_GENERAL.shelfId]}
          isDisabled
        />
      </uiLib.Tooltip>
      <uiLib.Tooltip title={docDetails[DOCUMENT_GENERAL.rootShelfId]}>
        <uiLib.TextField
          classNames={{
            labeledFieldContainer: 'input_1'
          }}
          label="Root Shelf Id"
          value={docDetails[DOCUMENT_GENERAL.rootShelfId]}
          isDisabled
        />
      </uiLib.Tooltip>
      <Textarea
        className="input_2"
        label="Summary"
        name={DOCUMENT_GENERAL.summaryText}
        value={docDetails[DOCUMENT_GENERAL.summaryText]}
        isDisabled={formattedData?.disableEdit}
        onChange={handleChangesEvent}
      />
      <Textarea
        className="input_2"
        label="Full Text"
        name={DOCUMENT_GENERAL.fullText}
        value={docDetails[DOCUMENT_GENERAL.fullText]}
        isDisabled={formattedData?.disableEdit}
        onChange={handleChangesEvent}
      />
      <uiLib.Tooltip title={docDetails[DOCUMENT_GENERAL.fullTextHash]}>
        <TextInput
          className="input_1"
          label="Full Text Hash"
          name={DOCUMENT_GENERAL.fullTextHash}
          value={docDetails[DOCUMENT_GENERAL.fullTextHash]}
          isDisabled
          onChange={() => {}}
        />
      </uiLib.Tooltip>
      <uiLib.Tooltip title={docDetails[DOCUMENT_GENERAL.pdfHash]}>
        <TextInput
          className="input_1"
          label="PDF Hash"
          name={DOCUMENT_GENERAL.pdfHash}
          value={docDetails[DOCUMENT_GENERAL.pdfHash]}
          isDisabled
          onChange={() => {}}
        />
      </uiLib.Tooltip>
      <TextInput
        className="input_1"
        label="API Table Hash"
        name={DOCUMENT_GENERAL.apiTable}
        value={docDetails[DOCUMENT_GENERAL.apiTable]}
        isDisabled={formattedData?.disableEdit}
        onChange={handleChangesEvent}
      />
      <TextInput
        className="input_1"
        label="Meta Table"
        name={DOCUMENT_GENERAL.metaTable}
        value={docDetails[DOCUMENT_GENERAL.metaTable]}
        isDisabled={formattedData?.disableEdit}
        onChange={handleChangesEvent}
      />
      <uiLib.LabeledField label="Document Labels" className="input_2">
        <uiLib.Select
          type={uiLib.SELECT_TYPES.DEFAULT}
          isClearable
          isSearchable
          isMulti
          isCreatable
          isAsync
          isDisabled={formattedData?.disableEdit}
          shouldFetchDefaultOptions
          shouldKeepInputValue
          loadOptions={handleFetch}
          value={docDetails[DOCUMENT_GENERAL.documentLabels]}
          placeholder="# eitl labels"
          isLoading={createEitlLabelsDataMutation.isLoading}
          onChange={values => handleChanges(DOCUMENT_GENERAL.documentLabels, values)}
          onCreateOption={name => createEitlLabelsDataMutation.mutateAsync([{ name }])}
        />
      </uiLib.LabeledField>
      <uiLib.TextField
        classNames={{
          labeledFieldContainer: 'input_2'
        }}
        label="Enforcement Respondent"
        value={docDetails[DOCUMENT_GENERAL.enforcementRespondent]}
        isDisabled
      />
      <uiLib.TextField
        classNames={{
          labeledFieldContainer: 'input_2'
        }}
        label="Enforcement Penalty"
        value={docDetails[DOCUMENT_GENERAL.enforcementPenalty]}
        isDisabled
      />
      <uiLib.TextField
        classNames={{
          labeledFieldContainer: 'input_2'
        }}
        label="Enforcement Violation"
        value={docDetails[DOCUMENT_GENERAL.enforcementViolation]}
        isDisabled
      />
      <uiLib.LabeledField label="Master document" className="input_2">
        <uiLib.Select
          type={uiLib.SELECT_TYPES.DEFAULT}
          isDisabled
          isClearable
          value={docDetails[DOCUMENT_GENERAL.isMasterDoc]}
          options={constants.SELECT_OPTIONS}
        />
      </uiLib.LabeledField>
      <uiLib.TextField
        classNames={{
          labeledFieldContainer: 'input_2'
        }}
        label="Master Document ID"
        value={docDetails[DOCUMENT_GENERAL.masterDocId]}
        isDisabled
      />
      <uiLib.TextField
        classNames={{
          labeledFieldContainer: 'input_2'
        }}
        label="Spider Name"
        value={docDetails[DOCUMENT_GENERAL.spiderName]}
        isDisabled
      />
      <uiLib.LabeledField
        label={
          <uiLib.Typography>
            Citation Ids
            <uiLib.ActionButton
              onClick={handlers.handleCopyCitations}
              classNames={{
                button: 'document-details_fields_citations'
              }}
            >
              <uiLib.IconFileCopy />
            </uiLib.ActionButton>
          </uiLib.Typography>
        }
        className="input_2"
      >
        <uiLib.Select
          type={uiLib.SELECT_TYPES.DEFAULT}
          isDisabled
          isClearable
          isMulti
          value={docDetails[DOCUMENT_GENERAL.citationIds]}
          options={docDetails[DOCUMENT_GENERAL.citationIds]}
        />
      </uiLib.LabeledField>
      <uiLib.LabeledField label="Comment Close Date" className="input_2">
        <uiLib.DatePicker
          value={docDetails[DOCUMENT_GENERAL.commentOn]}
          isDisabled
          format={uiLib.getLocalDateFormat()}
        />
      </uiLib.LabeledField>
      <dl className="input_2">
        <dt>
          <Typography variant="body">Document Languages</Typography>
        </dt>
        {Array.isArray(docDetails[DOCUMENT_GENERAL.documentLanguages])
          ? docDetails[DOCUMENT_GENERAL.documentLanguages].map(item => {
              const language = _.get(item, 'language', {});
              return (
                <dd className="language-wrap">
                  <div>{`${language.name} (${
                    language.id
                  }), verified: ${language.verified.toString()}`}</div>
                  <div>{`Confidence: ${item.confidence}`}</div>
                </dd>
              );
            })
          : 'Not provided'}
      </dl>
    </div>
  );
};

DocumentDetailsFields.propTypes = {
  docDetails: PropTypes.shape({}).isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  editMode: PropTypes.bool,
  handleChanges: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  action: PropTypes.string
};

DocumentDetailsFields.defaultProps = {
  editMode: false,
  action: ''
};

export default DocumentDetailsFields;
