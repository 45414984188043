import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as productServiceConstants from 'admin/constants/productServices';
import * as constants from '../ProductServicesFilters.constants';
import { useProductServiceFiltersAsyncLoaders } from 'admin/containers/ContentManagement/ProductServices/elements/ProductServicesFilters/hooks/useProductServicesFiltersAsyncLoaders';

export const useProductServicesFiltersConfig = ({
  filterAsyncLoaders
}: {
  filterAsyncLoaders: ReturnType<typeof useProductServiceFiltersAsyncLoaders>;
}): ComponentProps<typeof uiLib.Filters>['config'] => [
  {
    key: productServiceConstants.PRODUCT_SERVICES_ATTRIBUTES.CREATED_AT,
    label: 'Created At',
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: productServiceConstants.PRODUCT_SERVICES_ATTRIBUTES.UPDATED_AT,
    label: 'Updated At',
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: productServiceConstants.PRODUCT_SERVICES_ATTRIBUTES.IS_ACTIVE,
    label: 'Status',
    type: uiLib.FILTER_TYPES.SELECT,
    inputComponentProps: {
      options: constants.BOOLEAN_OPTIONS
    }
  },
  {
    key: productServiceConstants.PRODUCT_SERVICES_ATTRIBUTES.DESCRIPTION,
    label: 'Description',
    type: uiLib.FILTER_TYPES.TEXT_WITH_MODIFIERS,
    inputComponentProps: {
      shouldUseExtraModifiers: true
    }
  },
  {
    key: productServiceConstants.PRODUCT_SERVICES_ATTRIBUTES.BUSINESS_LINE,
    label: 'Meta Area of Focus',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad:
      filterAsyncLoaders[productServiceConstants.PRODUCT_SERVICES_ATTRIBUTES.BUSINESS_LINE]
  }
];
