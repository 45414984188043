import type { MetaConceptsHandlers } from './useMetaConceptsHandlers.types';
import type { ComponentProps } from 'react';
import type { MetaConceptFromResponse } from 'shared/features/metaConcepts/metaConcepts.types';

import ROUTES from 'admin/constants/routes';

import * as uiLib from '@compliance.ai/web-components';
import * as metaConceptsConstants from 'admin/constants/metaConcepts';

export const useMetaConceptsSelector = () => {
  return (
    data: Awaited<ReturnType<MetaConceptsHandlers['handleMetaConceptsFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      count: data.count,
      results: data.results.map(row => {
        const meta_concept = row as MetaConceptFromResponse;
        return {
          [metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.ID]:
            meta_concept[metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.ID],
          [metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.NAME]:
            meta_concept[metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.NAME],
          [metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.NAME]: {
            href: `${window.location.origin}${ROUTES.metaConceptTool}/${
              meta_concept[metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.ID]
            }`,
            tooltip: meta_concept[metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.NAME],
            children: meta_concept[metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.NAME]
          } as uiLib.LinkCellValue,
          [metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.CREATED_AT]:
            meta_concept[metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.CREATED_AT],
          [metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.UPDATED_AT]:
            meta_concept[metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.UPDATED_AT],
          [metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.IS_ACTIVE]:
            meta_concept[metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.IS_ACTIVE],
          [metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.CONCEPTS]:
            meta_concept[metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.CONCEPTS]
        };
      })
    };
  };
};
