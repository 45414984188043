import { fetch, BASE_API_OPTIONS } from 'fetch';
import * as apiTypes from './businessLines.api.types';

export const api_getBusinessLines: apiTypes.GetBusinessLines = params => {
  return fetch({
    url: `/business_lines`,
    dataType: 'BusinessLine',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
export const api_postBusinessLines: apiTypes.AddBusinessLine = params => {
  return fetch({
    url: `/business_lines`,
    dataType: 'BusinessLine',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const api_putBusinessLines: apiTypes.ModifyBusinessLine = (id, params) => {
  return fetch({
    url: `/business_lines/${id}`,
    dataType: 'BusinessLine',
    method: 'PATCH',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const api_deleteBusinessLines: apiTypes.DeleteBusinessLine = id => {
  return fetch({
    url: `/business_lines/${id}`,
    dataType: 'BusinessLine',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
};
