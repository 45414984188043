import React, { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './BusinessLines.constants';
import * as conceptsConstants from 'admin/constants/businessLines';
import * as elements from './elements';
import * as errorUtils from 'shared/utils/errors';

import './_business_lines.scss';

import {
  useBusinessLinesData,
  useBusinessLinesHandlers,
  useBusinessLinesTableConfig,
  useBusinessLinesSelector
} from './hooks';

const BusinessLines: FC = () => {
  const { localState, localActions } = useBusinessLinesData();
  const handlers = useBusinessLinesHandlers({ localState, localActions });

  const tableConfig = useBusinessLinesTableConfig();

  const tableDataSelector = useBusinessLinesSelector();

  return (
    <uiLib.ErrorBoundary>
      <div className="business_lines__container">
        <div className="business_lines__filters">
          <elements.BusinessLinesFilters onSubmit={handlers.handleFiltersSubmit} />
          <uiLib.Button onClick={handlers.handleCreateNewBusinessLine}>
            Add Meta Area of Focus
          </uiLib.Button>
        </div>
        <div className="meat_concepts__table-wrapper">
          <uiLib.Table
            shouldShowTableOptions
            externalParams={localState.tableParams}
            rowKey={conceptsConstants.BUSINESS_LINES_ATTRIBUTES.ID}
            columns={tableConfig.columns}
            onDataFetch={handlers.handleBusinessLinesFetch}
            onParamsChange={localActions.setTableParams}
            reloadTrigger={localState.reloadTrigger}
            localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
            shouldShowColumnsSettingsButton
            shouldShowFirstPageButton
            shouldShowLastPageButton
            maxAllowedRowsCount={constants.ALLOWED_SELECTED_BUSINESS_LINES_COUNT}
            rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
            initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
            queryOptions={{
              select: tableDataSelector,
              onError: errorUtils.logReactQueryError
            }}
            classNames={{
              container: 'business_lines__table-outer-container',
              tableContainer: 'business_lines__table-inner-container',
              toolbar: 'business_lines__table-toolbar',
              headerCell: 'business_lines__table-header-cell',
              selectedRowsCount: 'business_lines__table-selected-rows-count'
            }}
          />
        </div>
      </div>
    </uiLib.ErrorBoundary>
  );
};

export default BusinessLines;
