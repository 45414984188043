import type { FC } from 'react';
import type { BusinessLinesFiltersProps } from './BusinessLinesFilters.types';

import * as uiLib from '@compliance.ai/web-components';

import { useBusinessLinesFiltersConfig, useBusinessLinesFiltersHandlers } from './hooks';

import './_business-lines-filters.scss';

export const BusinessLinesFilters: FC<BusinessLinesFiltersProps> = ({ onSubmit }) => {
  const filterConfig = useBusinessLinesFiltersConfig();

  const handlers = useBusinessLinesFiltersHandlers({
    props: {
      onSubmit
    }
  });

  return (
    <uiLib.Filters
      config={filterConfig}
      values={{}}
      withSearch
      searchPlaceholder="Search Meta Area of Focus"
      onSubmit={handlers.handleFiltersSubmit}
      dateFormat={uiLib.getLocalDateFormat()}
      className="business-lines-filters__container"
    />
  );
};
