import type { GlobalState } from 'shared/reducers';
import type { BusinessLinePageData } from './useBusinessLinePageData.types';

import * as businessLinesSelectors from 'shared/features/businessLines/businessLine.selectors';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useBusinessLinePageData = (): BusinessLinePageData => {
  const { id } = useParams();

  const reduxState: BusinessLinePageData['reduxState'] = useSelector<
    GlobalState,
    BusinessLinePageData['reduxState']
  >(state => ({
    businessLine: businessLinesSelectors.getFirstBusinessLine(state)
  }));

  const formattedData: BusinessLinePageData['formattedData'] = useMemo(
    () => ({
      businessLineId: id,
      isActive: reduxState.businessLine?.is_active
    }),
    [id, reduxState.businessLine?.is_active]
  );

  return {
    reduxState,
    formattedData
  };
};
