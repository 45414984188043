import type { BusinessLinePageStatusProps } from '../BusinessLineStatus.types';
import type { BusinessLineStatusData } from './useBusinessLineStatusData.types';
import type { BusinessLinePageStatusHandlers } from './useBusinessLineStatusHandlers.types';

import ROUTES from 'admin/constants/routes';
import * as constants from '../BusinessLineStatus.constants';

import { useHistory } from 'admin/utils/hooks';
import { useLocation } from 'react-router-dom';

export const useBusinessLineStatusHandlers = ({
  props,
  localActions
}: {
  props: Pick<BusinessLinePageStatusProps, 'onChangeStatus'>;
  localActions: BusinessLineStatusData['localActions'];
}): BusinessLinePageStatusHandlers => {
  const history = useHistory();
  const location = useLocation();

  const handleStatusDropdownToggle: BusinessLinePageStatusHandlers['handleStatusDropdownToggle'] =
    isOpen => () => {
      localActions.setIsOpen(isOpen);
    };

  const handleListOptionClick: BusinessLinePageStatusHandlers['handleListOptionClick'] = ([
    option
  ]) => {
    switch (option.value) {
      case constants.BUSINESS_LINE_CREATION_VALUES.ACTIVE: {
        props.onChangeStatus(true);
        break;
      }

      case constants.BUSINESS_LINE_CREATION_VALUES.DEACTIVATED: {
        props.onChangeStatus(false);
        break;
      }
    }

    localActions.setIsOpen(false);
  };

  const handleGoBack: BusinessLinePageStatusHandlers['handleGoBack'] = () => {
    history.push(`${ROUTES.contentManagement}?tab=business_lines`, location.state);
  };

  return {
    handleStatusDropdownToggle,
    handleListOptionClick,
    handleGoBack
  };
};
