import ROUTES from './routes';

const nameOfCategories = {
  adminSettings: 'adminSettings',
  devOps: 'devOps',
  documentQA: 'documentQA',
  documentData: 'documentData',
  jurisdictionAnalyzer: 'jurisdictionAnalyzer',
  topics: 'topics',
  userManagement: 'userManagement',
  visualizationPrototypes: 'visualizationPrototypes',
  myAnnotationTasks: 'myAnnotationTasks',
  customSettings: 'customSettings'
};

export default {
  [nameOfCategories.adminSettings]: {
    title: 'Admin Settings',
    value: nameOfCategories.adminSettings,
    subcategory: [{ path: ROUTES.adminRoles, title: 'Admin Roles' }]
  },
  [nameOfCategories.devOps]: {
    title: 'DevOps',
    value: nameOfCategories.devOps,
    subcategory: [
      { path: ROUTES.commandsTools, title: 'Commands Tool' },
      { path: ROUTES.commandRequestTools, title: 'Command Requests Tools' }
    ]
  },
  [nameOfCategories.documentQA]: {
    title: 'Document QA',
    value: nameOfCategories.documentQA,
    subcategory: [
      { path: ROUTES.incompleteDocTool, title: 'Incomplete Documents' },
      { path: ROUTES.documentUpdate, title: 'Update Documents' }
    ]
  },
  [nameOfCategories.documentData]: {
    title: 'Document Data',
    value: nameOfCategories.documentData,
    subcategory: [
      { path: ROUTES.adminTool, title: 'Document Admin Tool' },
      { path: ROUTES.crawlerMonitoring, title: 'Manage & Monitor Crawls' },
      { path: ROUTES.documents, title: 'Documents Manager' },
      { path: ROUTES.userCreatedDocuments, title: 'User Created Documents' },
      { path: ROUTES.documentMetaCategories, title: 'Document Meta-categories' },
      { path: ROUTES.documentCategories, title: 'Document CaiCategories' },
      { path: ROUTES.jurisdictionAdminTools, title: 'Jurisdiction Admin Tool' },
      { path: ROUTES.crawlerPerformance, title: 'Crawler Performance Tool' },
      { path: ROUTES.collectionTool, title: 'Collection Admin Tool' },
      // hide routes
      { path: ROUTES.contentManagement, hidden: true },
      { path: ROUTES.conceptTool, hidden: true },
      { path: ROUTES.metaConceptTool, hidden: true },
      { path: ROUTES.productServiceTool, hidden: true },
      { path: ROUTES.businessLineTool, hidden: true },
      { path: ROUTES.agenciesAdminTool, hidden: true },
      { path: ROUTES.crawlerTool, title: 'Crawler Admin Tool', hidden: true },
      { path: ROUTES.agencyTool, title: 'Agency Admin Tool', hidden: true },
      // nested routes
      { path: ROUTES.sources, hidden: true }
    ]
  },
  [nameOfCategories.jurisdictionAnalyzer]: {
    title: 'Jurisdiction Analyzer',
    value: nameOfCategories.jurisdictionAnalyzer,
    subcategory: [
      { path: ROUTES.concepts, title: 'Concepts' },
      { path: ROUTES.metrics, title: 'Metrics' }
    ]
  },
  [nameOfCategories.topics]: {
    title: 'Topics',
    value: nameOfCategories.topics,
    subcategory: [
      { path: ROUTES.annotationTasks, title: 'Annotation Tasks' },
      { path: ROUTES.annotationTaskTopicGroups, title: 'Annotation Task Topic Groups' },
      { path: ROUTES.aggregatedAnnotations, title: 'Aggregated Annotations' },
      { path: ROUTES.topics, title: 'Topics' },
      { path: ROUTES.termSamplingGroups, title: 'Term Sampling Groups' },
      { path: ROUTES.topicsStats, title: 'Topics Stats' },
      { path: ROUTES.topicAnnotationModelValidations, title: 'Model Validation Table' },
      { path: ROUTES.onboardingResults, title: 'Onboarding Results' },
      { path: ROUTES.skippedDocuments, title: 'Skipped Documents' },
      // nested routes
      { path: ROUTES.annotationtool, hidden: true }
    ]
  },
  [nameOfCategories.userManagement]: {
    title: 'User Management',
    value: nameOfCategories.userManagement,
    subcategory: [
      { path: ROUTES.users, title: 'Users' },
      { path: ROUTES.organizations, title: 'Organizations' },
      { path: ROUTES.manageOrgTeams, title: 'Organization teams' },
      { path: ROUTES.subscriptions, title: 'User Subscriptions' },
      { path: ROUTES.invite, title: 'Invite Users' },
      { path: ROUTES.marketing, title: 'Marketing Campaigns' },
      { path: ROUTES.appNotifications, title: 'App Notifications' },
      { path: ROUTES.contributorStatistics, title: 'Contributor Statistics' },
      // nested routes
      { path: ROUTES.contributortool, hidden: true },
      { path: ROUTES.slotTool, hidden: true },
      { path: ROUTES.createOrganizations, hidden: true },
      { path: ROUTES.editOrganizations, hidden: true }
    ]
  },
  [nameOfCategories.visualizationPrototypes]: {
    title: 'Visualization/Prototypes',
    value: nameOfCategories.visualizationPrototypes,
    subcategory: [
      { path: ROUTES.volumeStatistics, title: 'Volume Statistics' },
      { path: ROUTES.googleAnalyticsReporting, title: 'Google Analytics Reporting' },
      { path: ROUTES.charts, title: 'Charts' },
      { path: ROUTES.agencyLandingPage, title: 'Agency Landing Page' },
      { path: ROUTES.publications, title: 'Publications' }
    ]
  },
  [nameOfCategories.myAnnotationTasks]: {
    title: 'My annotation tasks',
    value: nameOfCategories.myAnnotationTasks
  },
  [nameOfCategories.customSettings]: {
    title: 'Custom Settings',
    value: nameOfCategories.customSettings,
    subcategory: [{ path: ROUTES.customSettings, title: 'RSS' }]
  }
};

export { nameOfCategories };
