import type { BusinessLinesHandlers } from './useBusinessLinesHandlers.types';
import type { BusinessLinesData } from './useBusinessLinesData.types';

import ROUTES from 'admin/constants/routes';

import * as uiLib from '@compliance.ai/web-components';
import * as businessLineDataApi from 'shared/features/businessLines/businessLines.api';
import * as errorUtils from 'shared/utils/errors';
import * as helpers from './useBusinessLinesHandlers.helpers';

import { useNavigate } from 'react-router-dom';

export const useBusinessLinesHandlers = ({
  localState,
  localActions
}: {
  localState: BusinessLinesData['localState'];
  localActions: BusinessLinesData['localActions'];
}): BusinessLinesHandlers => {
  const navigate = useNavigate();
  const handleBusinessLinesFetch: BusinessLinesHandlers['handleBusinessLinesFetch'] = async (
    params,
    options
  ) => {
    try {
      const businessLinesResponse = await businessLineDataApi.api_getBusinessLines({
        ...params,
        ...localState.filters,
        [uiLib.TABLE_PARAMS.SORT]: helpers.getApiSortSettings(params[uiLib.TABLE_PARAMS.SORT])
      });

      return {
        results: businessLinesResponse.items,
        count: businessLinesResponse.count ?? 0
      };
    } catch (e) {
      errorUtils.logError(e);

      return {
        results: [],
        count: 0
      };
    }
  };

  const handleFiltersSubmit: BusinessLinesHandlers['handleFiltersSubmit'] = filters => {
    localActions.setTableParams({
      ...localState.tableParams,
      [uiLib.TABLE_PARAMS.OFFSET]: 0
    });
    localActions.setFilters(filters);
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  const handleCreateNewBusinessLine: BusinessLinesHandlers['handleCreateNewBusinessLine'] = () => {
    navigate(`${ROUTES.businessLineTool}/new`);
  };

  return {
    handleBusinessLinesFetch: handleBusinessLinesFetch,
    handleFiltersSubmit,
    handleCreateNewBusinessLine: handleCreateNewBusinessLine
  };
};
