// All routes should be added here
// if you add route to this file and to admin/routes.js
// please also check file 'admin/constants/dashboardCategory.js'
// dashboardCategory file include information about permissions
// and also used for dashboard render

export const externalAppRoutes = {
  // Not in App component
  login: '/login',
  proContributor: '/pro-contributor',
  settings: '/settings',
  support: '/support'
};

const internalAppRoutes = {
  iframe: '/iframe',
  agencyLandingPage: '/agency-landing-page',
  dashboard: '/dashboard',
  annotationtool: '/annotationtool',
  contributortool: '/contributortool',
  slotTool: '/slot-tool',
  createOrganizations: '/create-organizations',
  editOrganizations: '/edit-organizations'
};

const adminSettingsRoutes = {
  // Admin Settings
  adminRoles: '/admin-roles'
};

const devOpsRoutes = {
  // Dev Ops
  commandsTools: '/commands-tools',
  commandRequestTools: '/command-request-tools'
};

const documentQARoutes = {
  // Document QA
  incompleteDocTool: '/incomplete-doc-tool',
  documentUpdate: '/document-update'
};

const documentDataRoutes = {
  // Document Data
  adminTool: '/admin-tool',
  crawlerTool: '/crawler-tool',
  agencyTool: '/agency-tool',
  documents: '/documents',
  crawlerMonitoring: '/crawler-monitoring',
  userCreatedDocuments: '/user-created-documents',
  agenciesAdminTool: '/agencies_admin_tool', //TODO delete
  contentManagement: '/content-management',
  documentMetaCategories: '/document_meta_categories',
  documentCategories: '/document_categories',
  jurisdictionAdminTools: '/jurisdiction-admin-tools',
  crawlerPerformance: '/crawler-performance',
  collectionTool: '/collection-tool',
  sources: '/sources',
  conceptTool: '/concept-tool',
  metaConceptTool: '/meta-concept-tool',
  productServiceTool: '/product-service-tool',
  businessLineTool: '/business-line-tool'
};

const jurisdictionAnalyzerRoutes = {
  // Jurisdiction Analyzer
  concepts: '/concepts',
  metrics: '/metrics'
};

const topicsRoutes = {
  // Topics
  annotationTasks: '/annotation_tasks',
  annotationTaskTopicGroups: '/annotation_task_topic_groups',
  aggregatedAnnotations: '/aggregated_annotations',
  topics: '/topics',
  termSamplingGroups: '/term-sampling-groups',
  topicsStats: '/topics-stats',
  topicAnnotationModelValidations: '/topic_annotation_model_validations',
  onboardingResults: '/onboarding_results',
  skippedDocuments: '/skipped-documents'
};

const userManagementRoutes = {
  // User Management
  users: '/users',
  organizations: '/organizations',
  manageOrgTeams: '/manage-org-teams',
  subscriptions: '/subscriptions',
  invite: '/invite',
  marketing: '/marketing',
  appNotifications: '/app-notifications',
  contributorStatistics: '/contributor-statistics'
};

const visualizationPrototypesRoutes = {
  // Visualization/Prototypes
  volumeStatistics: '/volume-statistics',
  googleAnalyticsReporting: '/google-analytics-reporting',
  charts: '/charts',
  publications: '/publications'
};

const customSettingsRoutes = {
  // Custom Settings
  customSettings: '/custom-settings'
};

export default {
  // Not in App component
  ...externalAppRoutes,
  // Include in App component
  ...internalAppRoutes,
  ...adminSettingsRoutes,
  ...devOpsRoutes,
  ...documentQARoutes,
  ...documentDataRoutes,
  ...jurisdictionAnalyzerRoutes,
  ...topicsRoutes,
  ...userManagementRoutes,
  ...visualizationPrototypesRoutes,
  ...customSettingsRoutes
};
