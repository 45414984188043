import * as businessLineElements from 'admin/containers/BusinessLinePage/elements';
import * as elements from './elements';
import * as constants from './BusinessLineDetails.constants';
import Tabs from 'admin/components/Tabs';
import ConfirmationModal from 'admin/containers/CrawlerPage/ConfirmationModal';

import {
  useBusinessLineDetailsHandlers,
  useBusinessLineDetailsData,
  useBusinessLineDetailsLifecycle
} from './hooks';

import './BusinessLineDetails.styles.scss';

const BusinessLineDetails = ({}) => {
  const { localState, localActions, formattedData, reduxState } = useBusinessLineDetailsData();
  const detailsHandlers = useBusinessLineDetailsHandlers({
    localActions,
    localState,
    formattedData,
    reduxState
  });
  useBusinessLineDetailsLifecycle({
    onCalculateBusinessLineDetails: detailsHandlers.handleCalculateBusinessLine
  });

  return (
    <>
      <Tabs
        className="business-lines-navigation_tabs"
        headClassName="business-lines-navigation_tabs_head"
        tabClassName="business-lines-navigation_tabs_tab"
        extraInfo={
          <businessLineElements.BusinessLineStatus
            businessLineDetails={localState.businessLineDetails}
            onChangeStatus={detailsHandlers.handleStatusDropdownToggle}
          />
        }
      >
        <elements.BusinessLineDetailsGeneral
          label={'Meta Area of Focus Details'}
          editMode={localState.editMode}
          toggleEditMode={detailsHandlers.handleToggleEditMode}
          details={localState.businessLineDetails}
          saveChanges={detailsHandlers.handleSaveChanges}
          handleChanges={detailsHandlers.handleChanges}
          errorFields={localState.errorFields}
          isNewMode={formattedData.isNewMode}
          handleDelete={detailsHandlers.handleToggleConfirmationModal}
        />
      </Tabs>
      <ConfirmationModal
        show={localState.isOpen}
        mode={constants.DELETE_CONFIRMATION_MODAL}
        onHide={detailsHandlers.handleToggleConfirmationModal}
        modalAction={detailsHandlers.handleOnDelete}
      />
    </>
  );
};

export default BusinessLineDetails;
