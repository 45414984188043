import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './ScrapedCategories.constants';
import * as categoryConstants from 'admin/constants/categories';
import * as elements from './elements';
import * as errorUtils from 'shared/utils/errors';

import {
  useScrapedCategoriesData,
  useScrapedCategoriesHandlers,
  useScrapedCategoriesSelector,
  useScrapedCategoriesTableConfig
} from './hooks';

import './_scraped-categories-management.scss';

export const ScrapedCategories: FC = () => {
  const { localState, localActions } = useScrapedCategoriesData();

  const handlers = useScrapedCategoriesHandlers({ localState, localActions });

  const tableDataSelector = useScrapedCategoriesSelector();

  const tableConfig = useScrapedCategoriesTableConfig();

  return (
    <uiLib.ErrorBoundary>
      <div className="scraped-categories-management__container">
        <div className="scraped-categories-management__filters">
          <elements.CategoriesFilters onSubmit={handlers.handleFiltersSubmit} />
        </div>
        <div className="scraped-categories-management__table-wrapper">
          <uiLib.Table
            shouldShowTableOptions
            externalParams={localState.tableParams}
            rowKey={categoryConstants.CATEGORY_ATTRIBUTES.ID}
            columns={tableConfig.columns}
            onDataFetch={handlers.handleCategoriesFetch}
            onParamsChange={localActions.setTableParams}
            reloadTrigger={localState.reloadTrigger}
            localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
            shouldShowColumnsSettingsButton
            shouldShowFirstPageButton
            shouldShowLastPageButton
            maxAllowedRowsCount={constants.ALLOWED_SELECTED_SCRAPED_CATEGORIES_COUNT}
            rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
            initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
            queryOptions={{
              select: tableDataSelector,
              onError: errorUtils.logReactQueryError
            }}
            classNames={{
              container: 'scraped-categories-management__table-outer-container',
              tableContainer: 'scraped-categories-management__table-inner-container',
              toolbar: 'scraped-categories-management__table-toolbar',
              headerCell: 'scraped-categories-management__table-header-cell',
              selectedRowsCount: 'scraped-categories-management__table-selected-rows-count'
            }}
          />
        </div>
      </div>
    </uiLib.ErrorBoundary>
  );
};
