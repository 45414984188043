import type { DocumentVersionsDocketReduxState } from '../../hooks/useDocumentVersionsDocketData.types';
import type { DocumentVersionsFromResponse } from 'shared/features/documentData/documentData.types';
import type { DocumentVersionsDocketHandlers } from '../../hooks/useDocumentVersionsDocketHandlers.types';
import React from 'react';
import _ from 'lodash';
import Typography from 'admin/components/Typography';
import Button from 'admin/components/Button';
import DocumentVersionsDocketList from './elements/DocumentVersionsDocketList';
import { useDocumentVersionsDocketData } from '../../hooks/useDocumentVersionsDocketData';

const DocumentVersionsDocketInfo = ({
  docDetailsInfo,
  currentDocVersion,
  changeCurrentDocVersion
}: {
  docDetailsInfo: DocumentVersionsDocketReduxState['docDetailsInfo'];
  currentDocVersion?: DocumentVersionsFromResponse;
  changeCurrentDocVersion: DocumentVersionsDocketHandlers['changeCurrentDocVersion'];
}) => {
  const { formattedData } = useDocumentVersionsDocketData({
    docDetailsInfo
  });
  return (
    <div className="document-list_details_items">
      <div className="document-list_details_items_title">
        <div>
          <Typography variant="title" bold>
            {formattedData.versionsDocketTabTitle}
          </Typography>
        </div>
        <Button disabled onClick={() => {}} color="blue">
          Edit
        </Button>
      </div>
      <DocumentVersionsDocketList
        docVersions={formattedData.versionsDocket}
        currentDocVersionID={_.get(currentDocVersion, 'id', 0)}
        changeCurrentDocVersion={changeCurrentDocVersion}
      />
    </div>
  );
};

export default DocumentVersionsDocketInfo;
