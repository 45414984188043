import type { DocumentVersionsDocketReduxState } from './hooks/useDocumentVersionsDocketData.types';

import DocumentVersionsDocketInfo from './elements/DocumentVersionsDocketInfo/DocumentVersionsDocketInfo';

import { useDocumentVersionsDocketHandlers } from './hooks/useDocumentVersionsDocketHandlers';
import { useDocumentVersionsDocketLifecycle } from './hooks/useDocumentVersionsDocketLifecycle';

import { useDocumentVersionsDocketData } from './hooks/useDocumentVersionsDocketData';

const DocumentVersionsDocket = ({
  docDetailsInfo,
  docTimestamp
}: {
  docDetailsInfo: DocumentVersionsDocketReduxState['docDetailsInfo'];
  docTimestamp: DocumentVersionsDocketReduxState['docTimestamp'];
}) => {
  const { formattedData, localActions, localState, reduxState } = useDocumentVersionsDocketData({
    docDetailsInfo
  });

  const handlers = useDocumentVersionsDocketHandlers({
    formattedData,
    localActions,
    localState,
    reduxState
  });
  useDocumentVersionsDocketLifecycle({
    onRenderPdf: handlers?.handleRenderPdf
  });

  return (
    <div className="document-list">
      <div className="document-text" id="document-text" />
      <div className="document-list_details">
        <DocumentVersionsDocketInfo
          docDetailsInfo={docDetailsInfo}
          currentDocVersion={localState.docVersion}
          changeCurrentDocVersion={handlers.changeCurrentDocVersion}
        />
      </div>
    </div>
  );
};

export default DocumentVersionsDocket;
