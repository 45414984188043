import type { BusinessLinesFiltersHandlers } from './useBusinessLinesFiltersHandlers.types';
import type { BusinessLinesFiltersProps } from '../BusinessLinesFilters.types';

import * as helpers from './useBusinessLinesFiltersHandlers.helpers';

export const useBusinessLinesFiltersHandlers = ({
  props
}: {
  props: Pick<BusinessLinesFiltersProps, 'onSubmit'>;
}): BusinessLinesFiltersHandlers => {
  const handleFiltersSubmit: BusinessLinesFiltersHandlers['handleFiltersSubmit'] = (
    value,
    searchValue
  ) => {
    props.onSubmit(helpers.formatBusinessLinesQueryParams(value, searchValue));
  };

  return {
    handleFiltersSubmit
  };
};
