import * as metaConceptElements from 'admin/containers/MetaConceptPage/elements';
import * as elements from './elements';
import * as constants from './MetaConceptDetails.constants';
import Tabs from 'admin/components/Tabs';
import ConfirmationModal from 'admin/containers/CrawlerPage/ConfirmationModal';

import {
  useMetaConceptDetailsHandlers,
  useMetaConceptDetailsData,
  useMetaConceptDetailsLifecycle
} from './hooks';

import './MetaConceptDetails.styles.scss';

const MetaConceptDetails = ({}) => {
  const { localState, localActions, formattedData, reduxState } = useMetaConceptDetailsData();
  const detailsHandlers = useMetaConceptDetailsHandlers({
    localActions,
    localState,
    formattedData,
    reduxState
  });
  useMetaConceptDetailsLifecycle({
    onCalculateMetaConceptDetails: detailsHandlers.handleCalculateMetaConcept
  });

  return (
    <>
      <Tabs
        className="meta-concepts-navigation_tabs"
        headClassName="meta-concepts-navigation_tabs_head"
        tabClassName="meta-concepts-navigation_tabs_tab"
        extraInfo={
          <metaConceptElements.MetaConceptStatus
            metaConceptDetails={localState.metaConceptDetails}
            onChangeStatus={detailsHandlers.handleStatusDropdownToggle}
          />
        }
      >
        <elements.MetaConceptDetailsGeneral
          label={'Meta Concept Details'}
          editMode={localState.editMode}
          toggleEditMode={detailsHandlers.handleToggleEditMode}
          details={localState.metaConceptDetails}
          saveChanges={detailsHandlers.handleSaveChanges}
          handleChanges={detailsHandlers.handleChanges}
          errorFields={localState.errorFields}
          isNewMode={formattedData.isNewMode}
          handleDelete={detailsHandlers.handleToggleConfirmationModal}
          handleConceptsFetch={detailsHandlers.handleConceptsFetch}
        />
      </Tabs>
      <ConfirmationModal
        show={localState.isOpen}
        mode={constants.DELETE_CONFIRMATION_MODAL}
        onHide={detailsHandlers.handleToggleConfirmationModal}
        modalAction={detailsHandlers.handleOnDelete}
      />
    </>
  );
};

export default MetaConceptDetails;
