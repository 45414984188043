import type { ComponentProps } from 'react';
import type { useMetaConceptsFiltersAsyncLoaders } from './useMetaConceptsFiltersAsyncLoaders';

import * as uiLib from '@compliance.ai/web-components';
import * as metaConceptsConstants from 'admin/constants/metaConcepts';
import * as metaConceptsQueryConstants from 'shared/features/metaConcepts/metaConcepts.constants';
import * as constants from '../MetaConceptsFilters.constants';

export const useMetaConceptsFiltersConfig = ({
  filterAsyncLoaders
}: {
  filterAsyncLoaders: ReturnType<typeof useMetaConceptsFiltersAsyncLoaders>;
}): ComponentProps<typeof uiLib.Filters>['config'] => [
  {
    key: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.CREATED_AT,
    label: 'Created At',
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.UPDATED_AT,
    label: 'Updated At',
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.CONCEPTS,
    label: 'Concepts',
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad:
      filterAsyncLoaders[metaConceptsQueryConstants.META_CONCEPTS_DATA_FETCH_QUERY_PARAMS.CONCEPTS]
  }
];
