import type { BusinessLineDetailsHandlers } from '../../hooks/useBusinessLineDetailsHandlers.types';
import type { BusinessLineDetailsLocalState } from '../../hooks/useBusinessLineDetailsData.types';
import type { BusinessLineDetailsNormalized } from '../../BusinessLineDetails.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../../BusinessLineDetails.constants';

export const BusinessLineDetailsGeneral = ({
  label,
  editMode,
  toggleEditMode,
  details,
  saveChanges,
  handleChanges,
  errorFields,
  isNewMode,
  handleDelete
}: {
  label: string;
  editMode: boolean;
  toggleEditMode: BusinessLineDetailsHandlers['handleToggleEditMode'];
  details: BusinessLineDetailsNormalized;
  saveChanges: BusinessLineDetailsHandlers['handleSaveChanges'];
  handleChanges: BusinessLineDetailsHandlers['handleChanges'];
  errorFields: BusinessLineDetailsLocalState['errorFields'];
  isNewMode: boolean;
  handleDelete: BusinessLineDetailsHandlers['handleToggleConfirmationModal'];
}) => {
  return (
    <div className="business-lines-details">
      <div className="business-lines-details_body">
        <div className="general-info">
          <div className="general-info_header">
            <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.H5}>General info</uiLib.Typography>
            <div className="general-info_header_btn-block">
              {!isNewMode && (
                <uiLib.Button onClick={handleDelete} type={uiLib.BUTTON_TYPES.DELETE}>
                  Delete
                </uiLib.Button>
              )}
              <uiLib.Button onClick={toggleEditMode} type={uiLib.BUTTON_TYPES.SECONDARY}>
                {editMode ? 'Cancel' : 'Edit'}
              </uiLib.Button>
              {editMode && (
                <uiLib.Button type={uiLib.BUTTON_TYPES.SUCCESS} onClick={saveChanges}>
                  Save Changes
                </uiLib.Button>
              )}
            </div>
          </div>
          <div className="general-info_body">
            {/* First row */}
            <uiLib.TextField
              classNames={{
                labeledFieldContainer: 'input_4'
              }}
              label="Name"
              name={constants.BUSINESS_LINE_ATTRIBUTES.name}
              value={details.name}
              isDisabled={!editMode}
              onChange={value => handleChanges(constants.BUSINESS_LINE_ATTRIBUTES.name, value)}
              error={errorFields.includes(constants.BUSINESS_LINE_ATTRIBUTES.name)}
            />
            <uiLib.TextField
              classNames={{
                labeledFieldContainer: 'input_2'
              }}
              label="ID"
              name={constants.BUSINESS_LINE_ATTRIBUTES.id}
              value={details.id}
              isDisabled
              onChange={value => handleChanges(constants.BUSINESS_LINE_ATTRIBUTES.id, value)}
              error={errorFields.includes(constants.BUSINESS_LINE_ATTRIBUTES.id)}
            />
            {/* Second row */}
            <uiLib.TextField
              classNames={{
                labeledFieldContainer: 'input_4'
              }}
              label="Description"
              name={constants.BUSINESS_LINE_ATTRIBUTES.id}
              value={details.description}
              isDisabled={!editMode}
              onChange={value =>
                handleChanges(constants.BUSINESS_LINE_ATTRIBUTES.description, value)
              }
              error={errorFields.includes(constants.BUSINESS_LINE_ATTRIBUTES.description)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessLineDetailsGeneral;
