import type { BusinessLineDetailsNormalized } from '../../BusinessLineDetails/BusinessLineDetails.types';
import type { BusinessLineStatusData } from './useBusinessLineStatusData.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo, useState } from 'react';

export const useBusinessLineStatusData = ({
  businessLineDetails
}: {
  businessLineDetails: BusinessLineDetailsNormalized;
}): BusinessLineStatusData => {
  const [isOpen, setIsOpen] = useState<BusinessLineStatusData['localState']['isOpen']>(false);

  const localState: BusinessLineStatusData['localState'] = {
    isOpen
  };

  const localActions: BusinessLineStatusData['localActions'] = useMemo(
    () => ({
      setIsOpen
    }),
    []
  );

  const formattedData: BusinessLineStatusData['formattedData'] = useMemo(
    () => ({
      createdDate: uiLib.formatDate(businessLineDetails?.created_at, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      }),
      updatedDate: uiLib.formatDate(businessLineDetails?.updated_at, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      }),
      is_active: businessLineDetails?.is_active
    }),
    [
      businessLineDetails?.created_at,
      businessLineDetails?.updated_at,
      businessLineDetails?.is_active
    ]
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
