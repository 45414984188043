import type { BusinessLinesHandlers } from './useBusinessLinesHandlers.types';
import type { ComponentProps } from 'react';
import type { BusinessLineFromResponse } from 'shared/features/businessLines/businessLines.types';

import ROUTES from 'admin/constants/routes';

import * as uiLib from '@compliance.ai/web-components';
import * as businessLinesConstants from 'admin/constants/businessLines';

export const useBusinessLinesSelector = () => {
  return (
    data: Awaited<ReturnType<BusinessLinesHandlers['handleBusinessLinesFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      count: data.count,
      results: data.results.map(row => {
        const meta_concept = row as BusinessLineFromResponse;
        return {
          [businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.ID]:
            meta_concept[businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.ID],
          [businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.NAME]:
            meta_concept[businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.NAME],
          [businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.NAME]: {
            href: `${window.location.origin}${ROUTES.businessLineTool}/${
              meta_concept[businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.ID]
            }`,
            tooltip: meta_concept[businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.NAME],
            children: meta_concept[businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.NAME]
          } as uiLib.LinkCellValue,
          [businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.CREATED_AT]:
            meta_concept[businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.CREATED_AT],
          [businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.UPDATED_AT]:
            meta_concept[businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.UPDATED_AT],
          [businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.IS_ACTIVE]:
            meta_concept[businessLinesConstants.BUSINESS_LINES_ATTRIBUTES.IS_ACTIVE]
        };
      })
    };
  };
};
