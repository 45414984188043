import type { TabsProps } from '@compliance.ai/web-components';

import Sources from './Sources/Sources';
import Categories from './Categories';
import Glassdoor from './Glassdoor/Glassdoor';
import Concepts from './Concepts/Concepts';
import MetaConcepts from './MetaConcepts/MetaConcepts';
import NewsSources from './NewsSources';
import { EitlLabels } from './EitlLabels';
import ProductServices from './ProductServices/ProductServices';
import BusinessLines from './BusinessLines/BusinessLines';

export const CONTENT_MANAGEMENT_TAB_QUERY_PARAM = 'tab';

export enum CONTENT_MANAGEMENT_TAB_KEYS {
  SOURCES = 'sources',
  NEWS_PREMIUM_CONTENT = 'news_premium_content',
  CATEGORIES = 'categories',
  GLASSDOOR = 'glassdoor',
  CONCEPTS = 'concepts',
  META_CONCEPTS = 'meta_concepts',
  EITL_LABELS = 'eitl_labels',
  PRODUCT_SERVICES = 'product_services',
  BUSINESS_LINES = 'business_lines'
}

export const CONTENT_MANAGEMENT_TABS: Required<TabsProps>['tabs'] = [
  {
    title: 'Sources',
    content: <Sources />,
    [CONTENT_MANAGEMENT_TAB_QUERY_PARAM]: CONTENT_MANAGEMENT_TAB_KEYS.SOURCES
  },
  {
    title: 'News & Premium',
    content: <NewsSources />,
    [CONTENT_MANAGEMENT_TAB_QUERY_PARAM]: CONTENT_MANAGEMENT_TAB_KEYS.NEWS_PREMIUM_CONTENT
  },
  {
    title: 'Categories',
    content: <Categories />,
    [CONTENT_MANAGEMENT_TAB_QUERY_PARAM]: CONTENT_MANAGEMENT_TAB_KEYS.CATEGORIES
  },
  {
    title: 'Glassdoor',
    content: <Glassdoor />,
    [CONTENT_MANAGEMENT_TAB_QUERY_PARAM]: CONTENT_MANAGEMENT_TAB_KEYS.GLASSDOOR
  },
  {
    title: 'Concepts',
    content: <Concepts />,
    [CONTENT_MANAGEMENT_TAB_QUERY_PARAM]: CONTENT_MANAGEMENT_TAB_KEYS.CONCEPTS
  },
  {
    title: 'Meta Concepts',
    content: <MetaConcepts />,
    [CONTENT_MANAGEMENT_TAB_QUERY_PARAM]: CONTENT_MANAGEMENT_TAB_KEYS.META_CONCEPTS
  },
  {
    title: 'EITL Labels',
    content: <EitlLabels />,
    [CONTENT_MANAGEMENT_TAB_QUERY_PARAM]: CONTENT_MANAGEMENT_TAB_KEYS.EITL_LABELS
  },
  {
    title: 'Area of Focus',
    content: <ProductServices />,
    [CONTENT_MANAGEMENT_TAB_QUERY_PARAM]: CONTENT_MANAGEMENT_TAB_KEYS.PRODUCT_SERVICES
  },
  {
    title: 'Meta Area of Focus',
    content: <BusinessLines />,
    [CONTENT_MANAGEMENT_TAB_QUERY_PARAM]: CONTENT_MANAGEMENT_TAB_KEYS.BUSINESS_LINES
  }
];
