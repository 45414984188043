import type { DocumentVersionsDocketHandlers } from './useDocumentVersionsDocketHandlers.types';
import { useEffect } from 'react';

export const useDocumentVersionsDocketLifecycle = ({
  onRenderPdf
}: {
  onRenderPdf: DocumentVersionsDocketHandlers['handleRenderPdf'];
}): void => {
  useEffect(onRenderPdf, [onRenderPdf]);
};
