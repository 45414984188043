import MetaConceptDetails from './elements/MetaConceptDetails/MetaConceptDetails';

import {
  useMetaConceptPageData,
  useMetaConceptPageHandlers,
  useMetaConceptPageLifecycle
} from './hooks';

const MetaConceptPage = () => {
  const { formattedData, reduxState } = useMetaConceptPageData();
  const handlers = useMetaConceptPageHandlers({ formattedData });
  useMetaConceptPageLifecycle({ onInitialDataFetch: handlers.handleLoadMetaConcept });

  return <MetaConceptDetails />;
};

export default MetaConceptPage;
