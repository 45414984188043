import type { BusinessLineEditHandlers } from '../../hooks/useBusinessLinePageHandlers.types';
import type { BusinessLineDetailsNormalized } from '../BusinessLineDetails/BusinessLineDetails.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useBusinessLineStatusData,
  useBusinessLineStatusHandlers,
  useBusinessLineStatusListConfig
} from './hooks';

export const BusinessLineStatus = ({
  businessLineDetails,
  onChangeStatus
}: {
  businessLineDetails: BusinessLineDetailsNormalized;
  onChangeStatus: BusinessLineEditHandlers['handleUpdateBusinessLineStatus'];
}) => {
  const { localActions, localState, formattedData } = useBusinessLineStatusData({
    businessLineDetails: businessLineDetails
  });

  const handlers = useBusinessLineStatusHandlers({
    props: {
      onChangeStatus
    },
    localActions
  });
  const listConfig = useBusinessLineStatusListConfig();
  return (
    <div className="business-lines-navigation_status">
      <div className="business-lines-navigation_status_date-block">
        <uiLib.Typography isItalic>{`Created ${formattedData.createdDate}`}</uiLib.Typography>
        <uiLib.Typography isItalic>{`Updated ${formattedData.updatedDate}`}</uiLib.Typography>
      </div>
      <div className="business-lines-navigation_status_status-block">
        <uiLib.Typography>Status</uiLib.Typography>
        <uiLib.Dropdown
          className="business-lines-navigation_status_status-block_dropdown"
          isOpen={localState.isOpen}
          onClose={handlers.handleStatusDropdownToggle(false)}
          triggerContent={
            <uiLib.Button
              className="business-lines-navigation_status_status-block_dropdown_trigger"
              onClick={handlers.handleStatusDropdownToggle(true)}
              type={
                formattedData.is_active ? uiLib.BUTTON_TYPES.SUCCESS : uiLib.BUTTON_TYPES.DELETE
              }
            >
              {formattedData.is_active ? <uiLib.IconCheck /> : <uiLib.IconWarning />}
              <uiLib.Typography>
                {formattedData.is_active ? 'Activated' : 'Deactivated'}
              </uiLib.Typography>
              <uiLib.IconEdit />
            </uiLib.Button>
          }
          dropdownContent={
            <uiLib.List
              selectedOptions={[]}
              onOptionClick={handlers.handleListOptionClick}
              options={listConfig.options}
            />
          }
        />
      </div>
      <div className="business-lines-navigation_status_icon-block">
        <uiLib.Button onClick={handlers.handleGoBack} type={uiLib.BUTTON_TYPES.SECONDARY}>
          <uiLib.IconClose fontSize="large" />
        </uiLib.Button>
      </div>
    </div>
  );
};
