import React, { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './Concepts.constants';
import * as conceptsConstants from 'admin/constants/concepts';
import * as elements from './elements';
import * as errorUtils from 'shared/utils/errors';

import './_concepts.scss';

import {
  useConceptsData,
  useConceptsHandlers,
  useConceptsTableConfig,
  useConceptsSelector
} from './hooks';

const Concepts: FC = () => {
  const { localState, localActions } = useConceptsData();
  const handlers = useConceptsHandlers({ localState, localActions });

  const tableConfig = useConceptsTableConfig();

  const tableDataSelector = useConceptsSelector();

  return (
    <uiLib.ErrorBoundary>
      <div className="concepts__container">
        <div className="concepts__filters">
          <elements.ConceptsFilters onSubmit={handlers.handleFiltersSubmit} />
          <uiLib.Button onClick={handlers.handleCreateNewConcept}>Add Concept</uiLib.Button>
        </div>
        <div className="concepts__table-wrapper">
          <uiLib.Table
            shouldShowTableOptions
            externalParams={localState.tableParams}
            rowKey={conceptsConstants.CONCEPTS_ATTRIBUTES.ID}
            columns={tableConfig.columns}
            onDataFetch={handlers.handleConceptsFetch}
            onParamsChange={localActions.setTableParams}
            reloadTrigger={localState.reloadTrigger}
            localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
            shouldShowColumnsSettingsButton
            shouldShowFirstPageButton
            shouldShowLastPageButton
            maxAllowedRowsCount={constants.ALLOWED_SELECTED_CONCEPTS_COUNT}
            rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
            initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
            queryOptions={{
              select: tableDataSelector,
              onError: errorUtils.logReactQueryError
            }}
            classNames={{
              container: 'concepts__table-outer-container',
              tableContainer: 'concepts__table-inner-container',
              toolbar: 'concepts__table-toolbar',
              headerCell: 'concepts__table-header-cell',
              selectedRowsCount: 'concepts__table-selected-rows-count'
            }}
          />
        </div>
      </div>
    </uiLib.ErrorBoundary>
  );
};

export default Concepts;
