import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

import * as actions from '../businessLines.actions';

export const useBusinessLinesReduxActions = () => {
  const dispatch = useDispatch();

  const addBusinessLine = useCallback(
    async (...args) => {
      return dispatch(actions.createBusinessLines(...args));
    },
    [dispatch]
  );
  const fetchBusinessLine = useCallback(
    async (...args) => {
      return dispatch(actions.getBusinessLines(...args));
    },
    [dispatch]
  );

  const updateBusinessLine = useCallback(
    async (...args) => {
      return dispatch(actions.updateBusinessLines(...args));
    },
    [dispatch]
  );

  const clearBusinessLines = useCallback(
    async (...args) => {
      return dispatch(actions.clearBusinessLines(...args));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      addBusinessLine: addBusinessLine,
      clearBusinessLines: clearBusinessLines,
      fetchBusinessLine: fetchBusinessLine,
      updateBusinessLine: updateBusinessLine
    }),
    [addBusinessLine, clearBusinessLines, fetchBusinessLine, updateBusinessLine]
  );
};
