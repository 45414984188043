import type { BusinessLineDetailsData } from './useBusinessLineDetailsData.types';
import type { GlobalState } from 'shared/reducers';
import type { CrawlDetailsData } from 'admin/containers/CrawlerPage/CrawlerDetails/hooks/useCrawlDetailsData.types';

import * as businessLinesSelectors from 'shared/features/businessLines/businessLine.selectors';
import {
  BUSINESS_LINE_DETAILS,
  NEW_BUSINESS_LINE_ID
} from 'admin/containers/BusinessLinePage/elements/BusinessLineDetails/BusinessLineDetails.constants';
import { getCurrentUser } from 'shared/features/user/user.selectors';

import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
export const useBusinessLineDetailsData = (): BusinessLineDetailsData => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState<BusinessLineDetailsData['localState']['isOpen']>(false);
  const [editMode, setEditMode] = useState<BusinessLineDetailsData['localState']['editMode']>(
    id === NEW_BUSINESS_LINE_ID
  );

  const [errorFields, setErrorFields] = useState<CrawlDetailsData['localState']['errorFields']>([]);
  const [businessLineDetails, setBusinessLineDetails] =
    useState<BusinessLineDetailsData['localState']['businessLineDetails']>(BUSINESS_LINE_DETAILS);

  const localState: BusinessLineDetailsData['localState'] = {
    isOpen,
    editMode,
    businessLineDetails,
    errorFields
  };

  const localActions: BusinessLineDetailsData['localActions'] = useMemo(
    () => ({
      setIsOpen,
      setBusinessLineDetails,
      setEditMode,
      setErrorFields
    }),
    []
  );
  const reduxState: BusinessLineDetailsData['reduxState'] = useSelector<
    GlobalState,
    BusinessLineDetailsData['reduxState']
  >(state => ({
    businessLine: businessLinesSelectors.getFirstBusinessLine(state),
    currentUser: getCurrentUser(state)
  }));

  const formattedData: BusinessLineDetailsData['formattedData'] = useMemo(
    () => ({
      businessLineId: String(id),
      isNewMode: id === NEW_BUSINESS_LINE_ID
    }),
    [id]
  );

  return {
    localState,
    localActions,
    formattedData,
    reduxState
  };
};
