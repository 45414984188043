import type * as apiTypes from './businessLines.api.types';

import * as uiLib from '@compliance.ai/web-components';

export const normalizeBusinessLinesResponse = (
  response: apiTypes.GetBusinessLinesResponse
): apiTypes.NormalizedBusinessLinesResponse => {
  return {
    count: response.count,
    labels: response.items.map(normalizeBusinessLine)
  };
};

export const normalizeBusinessLine = (
  businessLine: apiTypes.BusinessLineFromResponse
): apiTypes.NormalizedBusinessLine => {
  return {
    id: businessLine.id,
    name: businessLine.name,
    createdAt: uiLib.isValidDate(businessLine.created_at) ? businessLine.created_at : '',
    userId: businessLine.user_id
  };
};
