import type { FC } from 'react';
import type { MetaConceptConceptsCellProps } from './MetaConceptConceptsCell.types';
import type { MetaConceptFromResponse } from 'shared/features/metaConcepts/metaConcepts.types';

import * as uiLib from '@compliance.ai/web-components';
import { getMetaConceptConceptsInfo } from './MetaConceptConceptsCell.helpers';

export const MetaConceptConceptsCell: FC<MetaConceptConceptsCellProps> = ({ row }) => {
  const conceptValue = row as MetaConceptFromResponse;
  const concepts = getMetaConceptConceptsInfo(conceptValue?.concepts);
  return <uiLib.Typography>{concepts}</uiLib.Typography>;
};
