import type { FC } from 'react';
import type { ProductServicesFiltersProps } from './ProductServicesFilters.types';

import * as uiLib from '@compliance.ai/web-components';

import { useProductServicesFiltersConfig, useProductServicesFiltersHandlers } from './hooks';

import './_product-services-filters.scss';
import { useProductServiceFiltersAsyncLoaders } from 'admin/containers/ContentManagement/ProductServices/elements/ProductServicesFilters/hooks/useProductServicesFiltersAsyncLoaders';

export const ProductServicesFilters: FC<ProductServicesFiltersProps> = ({ onSubmit }) => {
  const filterAsyncLoaders = useProductServiceFiltersAsyncLoaders();
  const filterConfig = useProductServicesFiltersConfig({ filterAsyncLoaders });
  const handlers = useProductServicesFiltersHandlers({
    props: {
      onSubmit
    }
  });

  return (
    <uiLib.Filters
      config={filterConfig}
      values={{}}
      withSearch
      searchPlaceholder="Search Area of focus"
      onSubmit={handlers.handleFiltersSubmit}
      dateFormat={uiLib.getLocalDateFormat()}
      className="product-services-filters__container"
    />
  );
};
