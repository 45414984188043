import type { GlobalState } from 'shared/reducers';
import type { BusinessLineFromResponseV2 } from './businessLines.api.types';

import { createSelector } from 'reselect';

export const getBusinessLinesReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state.businessLines
);

export const getBusinessLines = createSelector(
  getBusinessLinesReducer,
  (currentBusinessLinesReducer): BusinessLineFromResponseV2[] => {
    return currentBusinessLinesReducer?.items;
  }
);

export const getFirstBusinessLine = createSelector(
  getBusinessLines,
  (BusinessLines: BusinessLineFromResponseV2[]): BusinessLineFromResponseV2 => BusinessLines?.[0]
);
