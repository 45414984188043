import type { BusinessLineDetailsData } from './useBusinessLineDetailsData.types';
import type { BusinessLineDetailsHandlers } from './useBusinessLineDetailsHandlers.types';

import ROUTES from 'admin/constants/routes';
import { NOTIFICATION_TYPES, setNotification } from 'admin/components/Notification';
import {
  CONTENT_MANAGEMENT_TAB_KEYS,
  CONTENT_MANAGEMENT_TAB_QUERY_PARAM
} from 'admin/containers/ContentManagement/ContentManagement.constants';
import * as helpers from '../BusinessLineDetails.helper';

import { useBusinessLinesApi } from 'shared/features/businessLines/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useHistory } from 'admin/utils/hooks';

export const useBusinessLineDetailsHandlers = ({
  localActions,
  localState,
  formattedData,
  reduxState
}: {
  localActions: BusinessLineDetailsData['localActions'];
  localState: BusinessLineDetailsData['localState'];
  formattedData: BusinessLineDetailsData['formattedData'];
  reduxState: BusinessLineDetailsData['reduxState'];
}): BusinessLineDetailsHandlers => {
  const history = useHistory();
  const location = useLocation();
  const navigate = useNavigate();
  const businessLinesApi = useBusinessLinesApi();

  const handleCalculateBusinessLine: BusinessLineDetailsHandlers['handleCalculateBusinessLine'] =
    useCallback(() => {
      const formatBusinessLineDetails = helpers.fromRawInfoToDetails(reduxState.businessLine);
      localActions.setBusinessLineDetails(formatBusinessLineDetails);
    }, [localActions, reduxState.businessLine]);

  const handleToggleEditMode: BusinessLineDetailsHandlers['handleToggleEditMode'] = () => {
    if (localState.editMode) {
      handleCalculateBusinessLine();
    }
    localActions.setEditMode(!localState.editMode);
  };

  const handleChanges: BusinessLineDetailsHandlers['handleChanges'] = (name, value) => {
    return localActions.setBusinessLineDetails(businessLineDetails => ({
      ...businessLineDetails,
      [name]: value
    }));
  };

  const handleSaveChanges: BusinessLineDetailsHandlers['handleSaveChanges'] = async () => {
    const { businessLineDetails: businessLineInfo, errors } = helpers.fromDetailsToRawInfo(
      localState.businessLineDetails,
      reduxState.businessLine
    );
    if (errors.length) {
      localActions.setErrorFields(errors.map(err => err?.title));
      errors.forEach(error => setNotification({ message: error, type: NOTIFICATION_TYPES.ERROR }));
      return;
    }
    handleToggleEditMode();
    if (!isNaN(Number(formattedData.businessLineId))) {
      try {
        const responseData = await businessLinesApi.modifyBusinessLine(
          Number(formattedData.businessLineId),
          {
            ...businessLineInfo
          }
        );

        localActions.setBusinessLineDetails({
          ...localState.businessLineDetails,
          ...responseData
        });
        if (responseData.id) {
          localActions.setErrorFields([]);
        }
      } catch (e) {
        // here we used to call errorUtils to do logError(e as Error);
        // that led to double notification along the error handling chain
      }
    } else {
      try {
        const responseData = await businessLinesApi.addBusinessLine(businessLineInfo);
        localActions.setBusinessLineDetails({
          ...localState.businessLineDetails,
          ...responseData
        });
        const responseDataId = responseData?.id;
        if (responseDataId) {
          localActions.setErrorFields([]);
          navigate(`${ROUTES.businessLineTool}/${responseDataId}`);
        }
      } catch (e) {
        // here we used to call errorUtils to do logError(e as Error);
        // that led to double notification along the error handling chain
      }
    }
  };

  const handleOnDelete: BusinessLineDetailsHandlers['handleOnDelete'] = async () => {
    await businessLinesApi.deleteBusinessLine(Number(formattedData.businessLineId));
    navigate(
      `${ROUTES.contentManagement}?${CONTENT_MANAGEMENT_TAB_QUERY_PARAM}=${CONTENT_MANAGEMENT_TAB_KEYS.META_CONCEPTS}`,
      {
        ...location.state,
        relative: 'path'
      }
    );
  };

  const handleToggleConfirmationModal: BusinessLineDetailsHandlers['handleToggleConfirmationModal'] =
    () => {
      localActions.setIsOpen(!localState.isOpen);
    };

  const handleStatusDropdownToggle: BusinessLineDetailsHandlers['handleStatusDropdownToggle'] =
    isOpen => () => {
      localActions.setIsOpen(isOpen);
    };

  const handleGoBack: BusinessLineDetailsHandlers['handleGoBack'] = () => {
    navigate(`${ROUTES.contentManagement}?tab=business_lines`, location.state);
  };

  return {
    handleStatusDropdownToggle,
    handleGoBack,
    handleCalculateBusinessLine,
    handleToggleEditMode,
    handleChanges,
    handleSaveChanges,
    handleOnDelete,
    handleToggleConfirmationModal
  };
};
