// ----- TABLE CONSTANTS -----

import { MetaConceptDetails, MetaConceptDetailsNormalized } from './MetaConceptDetails.types';

export const META_CONCEPT_ATTRIBUTES = {
  id: 'id',
  name: 'name',
  description: 'description',
  multiField: 'multi_input',
  isActive: 'is_active',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
  user: 'user',
  isShare: 'is_shared',
  concepts: 'concepts'
};

export const NEW_META_CONCEPT_ID = 'new';

// Errors

export const ERRORS = {
  commonError: 'This value is required'
};

// meta concept details used by MetaConceptDetails component (just help to show structure)
export const META_CONCEPT_DETAILS: MetaConceptDetailsNormalized = {
  // general info
  name: '', // string
  id: 'new', // number | new
  description: '', // string
  is_active: true, // boolean
  created_at: '', // string
  updated_at: '', // string
  is_shared: false, // boolean
  concepts: [] // array
};

export const META_CONCEPT_ACTIVE_TYPES = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated'
};

export const DELETE_CONFIRMATION_MODAL = {
  title: 'Delete meta concept',
  body: 'Are you sure you want to delete this meta concept?'
};
