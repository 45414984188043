import _ from 'lodash';
import moment from 'moment';
import { META_CONCEPT_ATTRIBUTES, ERRORS } from './MetaConceptDetails.constants';
import { DATE_TIME_FORMAT } from 'admin/constants/projectSettings';
import {
  MetaConceptDetails,
  MetaConceptDetailsNormalized,
  MetaConceptError
} from './MetaConceptDetails.types';
import type * as metaConceptApiTypes from 'shared/features/metaConcepts/metaConcepts.api.types';
import type {
  SetDifferencePropsCheckFunctionReturn,
  SetDifferencePropsPath,
  SetDifferencePropsValue
} from './MetaConceptDetails.types';

// ---------------- GET Concept Info ----------------

const formatTime = (date: string) => (date ? moment(date).format(DATE_TIME_FORMAT) : 'N/A');

// convert DB concept info to screen concept details
export const fromRawInfoToDetails = (
  meta_concept: metaConceptApiTypes.MetaConceptFromResponseV2
): MetaConceptDetailsNormalized =>
  ({
    [META_CONCEPT_ATTRIBUTES.name]: meta_concept?.name ?? '',
    [META_CONCEPT_ATTRIBUTES.id]: meta_concept?.id ?? 'new',
    [META_CONCEPT_ATTRIBUTES.description]: meta_concept?.description ?? '',
    [META_CONCEPT_ATTRIBUTES.isActive]: meta_concept?.is_active ?? false,
    [META_CONCEPT_ATTRIBUTES.createdAt]: formatTime(meta_concept?.created_at ?? ''),
    [META_CONCEPT_ATTRIBUTES.updatedAt]: formatTime(meta_concept?.updated_at ?? ''),
    [META_CONCEPT_ATTRIBUTES.isShare]: meta_concept?.is_shared ?? false,
    [META_CONCEPT_ATTRIBUTES.concepts]: (meta_concept?.concepts || []).map(concept => ({
      value: concept.id,
      label: concept.name
    }))
  } as MetaConceptDetailsNormalized);

// ---------------- POST Concept Info ----------------

// // check functions

export const checkRequiredFields = (
  valueNew: SetDifferencePropsValue,
  newInfo: MetaConceptDetailsNormalized
): SetDifferencePropsCheckFunctionReturn => !valueNew && ERRORS.commonError;

interface SetDifferenceProps {
  newPath: SetDifferencePropsPath;
  oldPath: SetDifferencePropsPath;
  superPath?: string[];
  predefinedValue?: SetDifferencePropsValue;
  checkFunction?: (
    valueNew: SetDifferencePropsValue,
    newInfo: MetaConceptDetailsNormalized
  ) => SetDifferencePropsCheckFunctionReturn;
}
// function that pre-formats details for API

export const fromDetailsToRawInfo = (
  newInfo: MetaConceptDetailsNormalized,
  oldInfo: metaConceptApiTypes.MetaConceptFromResponseV2
): {
  metaConceptDetails: Partial<MetaConceptDetails>;
  errors: MetaConceptError[];
} => {
  const metaConceptDetails = {};
  const errors: MetaConceptError[] = [];

  const setDifference = ({
    /*
      setDifference - function to check difference between old and edit data
        if there is some difference files send to BE for save

      newPath (required) - array
        - get new data
        - path in crawlDetails (check CRAWL_DETAILS const)
      oldPath (required) - array
        - get old data for check
        - path in original data from BE
        - used like path for set (show the right place)
      superPath (optional) - array
        - path to set data for request in special place
      predefinedValue (optional) - value (string, number, bool, array, object)
        - takes like new data (helps if data have special format or addition)
      checkFunction (optional) - function (return string)
        - function to check that new variable is correct
        - should get the value and return string error or false
    */
    newPath,
    oldPath,
    superPath,
    predefinedValue,
    checkFunction
  }: SetDifferenceProps) => {
    let valueNew;
    if (Boolean(predefinedValue)) valueNew = predefinedValue;
    else valueNew = predefinedValue || _.get(newInfo, newPath, null);

    const valueOld = _.get(oldInfo, oldPath, null);
    if (!_.isEqual(valueNew, valueOld)) {
      _.set(metaConceptDetails, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew, newInfo);
        if (result) errors.push({ text: result, title: String(newPath[0]) });
      }
    }
  };

  setDifference({
    newPath: [META_CONCEPT_ATTRIBUTES.name],
    oldPath: ['name'],
    checkFunction: checkRequiredFields
  });

  setDifference({
    newPath: [META_CONCEPT_ATTRIBUTES.description],
    oldPath: ['description']
  });
  setDifference({
    newPath: [META_CONCEPT_ATTRIBUTES.isActive],
    oldPath: ['is_active']
  });

  // check concepts
  const oldConcepts = (oldInfo?.concepts || []).map(item => item.id);
  const newConcepts = (newInfo?.concepts || []).map(item => item.value);
  if (!_.isEqual(oldConcepts, newConcepts)) {
    _.set(metaConceptDetails, 'concepts', newConcepts);
  }

  return { metaConceptDetails, errors };
};
