import type { ProductServiceDetailsHandlers } from '../../hooks/useProductServiceDetailsHandlers.types';
import type { ProductServiceDetailsLocalState } from '../../hooks/useProductServiceDetailsData.types';
import type { ProductServiceDetailsNormalized } from '../../ProductServiceDetails.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../../ProductServiceDetails.constants';
import { PRODUCT_SERVICE_ATTRIBUTES } from 'admin/containers/ProductServicePage/elements/ProductServiceDetails/ProductServiceDetails.constants';
import { loadBusinessLineOptions } from '../../../../../ProductServicePage/elements/ProductServiceDetails/ProductServiceDetails.helper';

export const ProductServiceDetailsGeneral = ({
  label,
  editMode,
  toggleEditMode,
  details,
  saveChanges,
  handleChanges,
  errorFields,
  isNewMode,
  handleDelete
}: {
  label: string;
  editMode: boolean;
  toggleEditMode: ProductServiceDetailsHandlers['handleToggleEditMode'];
  details: ProductServiceDetailsNormalized;
  saveChanges: ProductServiceDetailsHandlers['handleSaveChanges'];
  handleChanges: ProductServiceDetailsHandlers['handleChanges'];
  errorFields: ProductServiceDetailsLocalState['errorFields'];
  isNewMode: boolean;
  handleDelete: ProductServiceDetailsHandlers['handleToggleConfirmationModal'];
}) => {
  const disableEdit = !editMode;
  return (
    <div className="product-services-details">
      <div className="product-services-details_body">
        <div className="general-info">
          <div className="general-info_header">
            <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.H5}>General info</uiLib.Typography>
            <div className="general-info_header_btn-block">
              {!isNewMode && (
                <uiLib.Button onClick={handleDelete} type={uiLib.BUTTON_TYPES.DELETE}>
                  Delete
                </uiLib.Button>
              )}
              <uiLib.Button onClick={toggleEditMode} type={uiLib.BUTTON_TYPES.SECONDARY}>
                {editMode ? 'Cancel' : 'Edit'}
              </uiLib.Button>
              {editMode && (
                <uiLib.Button type={uiLib.BUTTON_TYPES.SUCCESS} onClick={saveChanges}>
                  Save Changes
                </uiLib.Button>
              )}
            </div>
          </div>
          <div className="general-info_body">
            {/* First row */}
            <uiLib.TextField
              classNames={{
                labeledFieldContainer: 'input_4'
              }}
              label="Name"
              name={constants.PRODUCT_SERVICE_ATTRIBUTES.name}
              value={details.name}
              isDisabled={!editMode}
              onChange={value => handleChanges(constants.PRODUCT_SERVICE_ATTRIBUTES.name, value)}
              error={errorFields.includes(constants.PRODUCT_SERVICE_ATTRIBUTES.name)}
            />
            <uiLib.LabeledField label="Associated Meta-Area of Focus" className="input_4">
              <uiLib.Select
                type={uiLib.SELECT_TYPES.DEFAULT}
                isAsync
                className="input_4"
                isDisabled={disableEdit}
                value={details?.business_line}
                onChange={e => handleChanges(PRODUCT_SERVICE_ATTRIBUTES.businessLine, e)}
                loadOptions={value => loadBusinessLineOptions('name', value, 'name')}
                isClearable
              />
            </uiLib.LabeledField>
            <uiLib.TextField
              classNames={{
                labeledFieldContainer: 'input_2'
              }}
              label="ID"
              name={constants.PRODUCT_SERVICE_ATTRIBUTES.id}
              value={details.id}
              isDisabled
              onChange={value => handleChanges(constants.PRODUCT_SERVICE_ATTRIBUTES.id, value)}
              error={errorFields.includes(constants.PRODUCT_SERVICE_ATTRIBUTES.id)}
            />
            {/* Second row */}
            <uiLib.TextField
              classNames={{
                labeledFieldContainer: 'input_4'
              }}
              label="Description"
              name={constants.PRODUCT_SERVICE_ATTRIBUTES.description}
              value={details.description}
              isDisabled={!editMode}
              onChange={value =>
                handleChanges(constants.PRODUCT_SERVICE_ATTRIBUTES.description, value)
              }
              error={errorFields.includes(constants.PRODUCT_SERVICE_ATTRIBUTES.description)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductServiceDetailsGeneral;
