import type { MetaConceptDetailsHandlers } from '../../hooks/useMetaConceptDetailsHandlers.types';
import type { MetaConceptDetailsLocalState } from '../../hooks/useMetaConceptDetailsData.types';
import type { MetaConceptDetailsNormalized } from '../../MetaConceptDetails.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../../MetaConceptDetails.constants';

export const MetaConceptDetailsGeneral = ({
  label,
  editMode,
  toggleEditMode,
  details,
  saveChanges,
  handleChanges,
  errorFields,
  isNewMode,
  handleDelete,
  handleConceptsFetch
}: {
  label: string;
  editMode: boolean;
  toggleEditMode: MetaConceptDetailsHandlers['handleToggleEditMode'];
  details: MetaConceptDetailsNormalized;
  saveChanges: MetaConceptDetailsHandlers['handleSaveChanges'];
  handleChanges: MetaConceptDetailsHandlers['handleChanges'];
  errorFields: MetaConceptDetailsLocalState['errorFields'];
  isNewMode: boolean;
  handleDelete: MetaConceptDetailsHandlers['handleToggleConfirmationModal'];
  handleConceptsFetch: MetaConceptDetailsHandlers['handleConceptsFetch'];
}) => {
  return (
    <div className="meta-concepts-details">
      <div className="meta-concepts-details_body">
        <div className="general-info">
          <div className="general-info_header">
            <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.H5}>General info</uiLib.Typography>
            <div className="general-info_header_btn-block">
              {!isNewMode && (
                <uiLib.Button onClick={handleDelete} type={uiLib.BUTTON_TYPES.DELETE}>
                  Delete
                </uiLib.Button>
              )}
              <uiLib.Button onClick={toggleEditMode} type={uiLib.BUTTON_TYPES.SECONDARY}>
                {editMode ? 'Cancel' : 'Edit'}
              </uiLib.Button>
              {editMode && (
                <uiLib.Button type={uiLib.BUTTON_TYPES.SUCCESS} onClick={saveChanges}>
                  Save Changes
                </uiLib.Button>
              )}
            </div>
          </div>
          <div className="general-info_body">
            {/* First row */}
            <uiLib.TextField
              classNames={{
                labeledFieldContainer: 'input_4'
              }}
              label="Name"
              name={constants.META_CONCEPT_ATTRIBUTES.name}
              value={details.name}
              isDisabled={!editMode}
              onChange={value => handleChanges(constants.META_CONCEPT_ATTRIBUTES.name, value)}
              error={errorFields.includes(constants.META_CONCEPT_ATTRIBUTES.name)}
            />
            <uiLib.TextField
              classNames={{
                labeledFieldContainer: 'input_2'
              }}
              label="ID"
              name={constants.META_CONCEPT_ATTRIBUTES.id}
              value={details.id}
              isDisabled
              onChange={value => handleChanges(constants.META_CONCEPT_ATTRIBUTES.id, value)}
              error={errorFields.includes(constants.META_CONCEPT_ATTRIBUTES.id)}
            />
            {/* Second row */}
            <uiLib.TextField
              classNames={{
                labeledFieldContainer: 'input_4'
              }}
              label="Description"
              name={constants.META_CONCEPT_ATTRIBUTES.id}
              value={details.description}
              isDisabled={!editMode}
              onChange={value =>
                handleChanges(constants.META_CONCEPT_ATTRIBUTES.description, value)
              }
              error={errorFields.includes(constants.META_CONCEPT_ATTRIBUTES.description)}
            />
            {/* Third row */}
            <uiLib.LabeledField label="Concepts" className="input_4">
              <uiLib.Select
                type={uiLib.SELECT_TYPES.DEFAULT}
                isSearchable
                isAsync
                isMulti
                shouldFetchDefaultOptions
                placeholder="Concept"
                isDisabled={!editMode}
                loadOptions={handleConceptsFetch}
                value={details.concepts}
                onChange={value =>
                  handleChanges(
                    constants.META_CONCEPT_ATTRIBUTES.concepts,
                    value as uiLib.SelectOption[] | null
                  )
                }
              />
            </uiLib.LabeledField>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaConceptDetailsGeneral;
