import type { ComponentProps } from 'react';
import type * as uiLib from '@compliance.ai/web-components';

export const ALLOWED_SELECTED_BUSINESS_LINES_COUNT = 5000;

export const TABLE_LOCAL_STORAGE_KEY = 'metaConceptsManagement';

export const TABLE_FETCH_REQUEST_LIMIT = 10;

export enum SORT_DIRECTIONS {
  ASC = 'asc',
  DESC = 'desc'
}
export enum BUSINESS_LINES_ATTRIBUTES {
  ID = 'id',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  NAME = 'name',
  DESCRIPTION = 'description',
  IS_ACTIVE = 'is_active'
}

export const ROWS_PER_PAGE_OPTIONS: Required<
  ComponentProps<typeof uiLib.Table>
>['rowsPerPageOptions'] = [10, 15, 20, 50, 100, 200, 500, 1000, 2500, 5000];
