import type { BusinessLinesData } from './useBusinessLinesData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../BusinessLines.constants';

import { useMemo, useState } from 'react';

export const useBusinessLinesData = (): BusinessLinesData => {
  const [filters, setFilters] = useState<BusinessLinesData['localState']['filters']>({});
  const [tableParams, setTableParams] = useState<BusinessLinesData['localState']['tableParams']>({
    ...uiLib.DEFAULT_TABLE_PARAMS,
    [uiLib.TABLE_PARAMS.LIMIT]: constants.TABLE_FETCH_REQUEST_LIMIT
  });
  const [reloadTrigger, setReloadTrigger] =
    useState<BusinessLinesData['localState']['reloadTrigger']>(false);

  const localState: BusinessLinesData['localState'] = {
    filters,
    tableParams,
    reloadTrigger
  };

  const localActions: BusinessLinesData['localActions'] = useMemo(
    () => ({
      setFilters,
      setTableParams,
      setReloadTrigger
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
