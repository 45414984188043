import BusinessLineDetails from './elements/BusinessLineDetails/BusinessLineDetails';

import {
  useBusinessLinePageData,
  useBusinessLinePageHandlers,
  useBusinessLinePageLifecycle
} from './hooks';

const BusinessLinePage = () => {
  const { formattedData, reduxState } = useBusinessLinePageData();
  const handlers = useBusinessLinePageHandlers({ formattedData });
  useBusinessLinePageLifecycle({ onInitialDataFetch: handlers.handleLoadBusinessLine });

  return <BusinessLineDetails />;
};

export default BusinessLinePage;
