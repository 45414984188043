import type * as apiTypes from './businessLines.api.types';

import { fetch, BASE_API_OPTIONS } from 'fetch';

export const fetchBusinessLinesData: apiTypes.FetchBusinessLinesData = async params => {
  return fetch({
    url: `/business_lines`,
    dataType: 'BusinessLine',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
