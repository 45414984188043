import * as productServiceConstants from 'admin/constants/productServices';

import * as errorUtils from 'shared/utils/errors';

import * as businessLineApi from 'shared/features/businessLines/businessLines.apiV2';

import { BusinessLineFromResponse } from 'shared/features/businessLines/businessLines.api.types';

export const useProductServiceFiltersAsyncLoaders = () => {
  const handleBusinessLinesFetch = async (inputString: string) => {
    try {
      const response = await businessLineApi.fetchBusinessLinesData({ name: inputString });

      return response.items.map((businessLine: BusinessLineFromResponse) => ({
        value: businessLine.id,
        label: businessLine.name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  return {
    [productServiceConstants.PRODUCT_SERVICES_ATTRIBUTES.BUSINESS_LINE]: handleBusinessLinesFetch
  };
};
