import type { BusinessLineEditHandlers } from './useBusinessLinePageHandlers.types';

import { useEffect } from 'react';

export const useBusinessLinePageLifecycle = ({
  onInitialDataFetch
}: {
  onInitialDataFetch: BusinessLineEditHandlers['handleLoadBusinessLine'];
}) => {
  useEffect(onInitialDataFetch, [onInitialDataFetch]);
};
