import type { BusinessLinesApi } from './useBusinessLinesApi.types';

import * as api from '../businessLines.api';
import * as helpers from '../businessLines.api.helpers';
import * as errorUtils from 'shared/utils/errors';

import { StatusCodes } from 'http-status-codes';

export const useBusinessLinesApi = (): BusinessLinesApi => {
  const getBusinessLines: BusinessLinesApi['getBusinessLines'] = async params => {
    try {
      const response = await api.api_getBusinessLines(params);

      return helpers.normalizeBusinessLinesResponse(response);
    } catch (e) {
      errorUtils.logError(e as Error);
      throw e;
    }
  };

  const addBusinessLine: BusinessLinesApi['addBusinessLine'] = async params => {
    try {
      const response = await api.api_postBusinessLines(params);

      return helpers.normalizeBusinessLine(response);
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      // we used to call uilib showNotification method here , but now
      // we just rely on the caller that catches and handles the exception
      // in any case; this way avoid double notification

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  const modifyBusinessLine: BusinessLinesApi['modifyBusinessLine'] = async (id, params) => {
    try {
      const response = await api.api_putBusinessLines(id, params);

      return helpers.normalizeBusinessLine(response);
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      // we used to call uilib showNotification method here , but now
      // we just rely on the caller that catches and handles the exception
      // in any case; this way avoid double notification

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  const deleteBusinessLine: BusinessLinesApi['deleteBusinessLine'] = async id => {
    try {
      return await api.api_deleteBusinessLines(id);
    } catch (e) {
      errorUtils.logError(e as Error, 'Error');
      throw e;
    }
  };

  return {
    getBusinessLines,
    addBusinessLine,
    modifyBusinessLine,
    deleteBusinessLine
  };
};
