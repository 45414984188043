import _ from 'lodash';
import moment from 'moment';
import { PRODUCT_SERVICE_ATTRIBUTES, ERRORS } from './ProductServiceDetails.constants';
import { DATE_TIME_FORMAT } from 'admin/constants/projectSettings';
import type {
  ProductServiceDetails,
  ProductServiceDetailsNormalized,
  ProductServiceError
} from './ProductServiceDetails.types';
import type * as productServiceApiTypes from 'shared/features/productServices/productServices.api.types';
import type {
  SetDifferencePropsCheckFunctionReturn,
  SetDifferencePropsPath,
  SetDifferencePropsValue
} from './ProductServiceDetails.types';
import type { ProductServiceFromResponse } from 'shared/features/productServices/productServices.types';
import { SelectOption } from '@compliance.ai/web-components';
import { api_getBusinessLines } from 'shared/features/businessLines/businessLines.api';

// ---------------- GET Concept Info ----------------

const formatTime = (date: string) => (date ? moment(date).format(DATE_TIME_FORMAT) : 'N/A');

const getBusinessLine = (productServiceInfo: ProductServiceFromResponse): SelectOption | null => {
  const id = productServiceInfo?.business_line?.id;
  const name = productServiceInfo?.business_line?.name;
  return _.isNumber(id) && name ? { value: id, label: name } : null;
};

// convert DB concept info to screen concept details
export const fromRawInfoToDetails = (
  product_service: productServiceApiTypes.ProductServiceFromResponseV2
): ProductServiceDetailsNormalized =>
  ({
    name: product_service?.name ?? '',
    id: product_service?.id ?? 'new',
    description: product_service?.description ?? '',
    is_active: product_service?.is_active ?? false,
    created_at: formatTime(product_service?.created_at ?? ''),
    updated_at: formatTime(product_service?.updated_at ?? ''),
    is_shared: product_service?.is_shared ?? false,
    business_line: getBusinessLine(product_service as ProductServiceFromResponse)
  } as ProductServiceDetailsNormalized);

// ---------------- POST Concept Info ----------------

// // check functions

export const checkRequiredFields = (
  valueNew: SetDifferencePropsValue,
  newInfo: ProductServiceDetails
): SetDifferencePropsCheckFunctionReturn => !valueNew && ERRORS.commonError;

interface SetDifferenceProps {
  newPath: SetDifferencePropsPath;
  oldPath: SetDifferencePropsPath;
  superPath?: string[];
  predefinedValue?: SetDifferencePropsValue;
  checkFunction?: (
    valueNew: SetDifferencePropsValue,
    newInfo: ProductServiceDetails
  ) => SetDifferencePropsCheckFunctionReturn;
}
// function that pre-formats details for API

export const fromDetailsToRawInfo = (
  newInfo: ProductServiceDetails,
  oldInfo: productServiceApiTypes.ProductServiceFromResponseV2
): {
  productServiceDetails: ProductServiceDetails;
  errors: ProductServiceError[];
} => {
  console.log('newInfo', newInfo);
  console.log('oldInfo', oldInfo);
  const productServiceDetails: productServiceApiTypes.ProductServiceFromResponseV2 = {
    id: '',
    name: '',
    description: '',
    created_at: '',
    is_active: false,
    is_shared: false,
    updated_at: ''
  };

  const errors: ProductServiceError[] = [];

  const setDifference = ({
    /*
      setDifference - function to check difference between old and edit data
        if there is some difference files send to BE for save

      newPath (required) - array
        - get new data
        - path in crawlDetails (check CRAWL_DETAILS const)
      oldPath (required) - array
        - get old data for check
        - path in original data from BE
        - used like path for set (show the right place)
      superPath (optional) - array
        - path to set data for request in special place
      predefinedValue (optional) - value (string, number, bool, array, object)
        - takes like new data (helps if data have special format or addition)
      checkFunction (optional) - function (return string)
        - function to check that new variable is correct
        - should get the value and return string error or false
    */
    newPath,
    oldPath,
    superPath,
    predefinedValue,
    checkFunction
  }: SetDifferenceProps) => {
    let valueNew;
    if (Boolean(predefinedValue)) valueNew = predefinedValue;
    else valueNew = predefinedValue || _.get(newInfo, newPath, null);

    const valueOld = _.get(oldInfo, oldPath, null);
    if (!_.isEqual(valueNew, valueOld)) {
      _.set(productServiceDetails, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew, newInfo);
        if (result) errors.push({ text: result, title: String(newPath[0]) });
      }
    }
  };

  setDifference({
    newPath: [PRODUCT_SERVICE_ATTRIBUTES.name],
    oldPath: ['name'],
    checkFunction: checkRequiredFields
  });

  setDifference({
    newPath: [PRODUCT_SERVICE_ATTRIBUTES.description],
    oldPath: ['description']
  });
  setDifference({
    newPath: [PRODUCT_SERVICE_ATTRIBUTES.isActive],
    oldPath: ['is_active']
  });
  setDifference({
    newPath: [PRODUCT_SERVICE_ATTRIBUTES.businessLine, 'value'],
    superPath: ['business_line_id'],
    oldPath: ['business_line, id']
  });

  return { productServiceDetails, errors };
};

const formatSelectOptions = (array: [], valueName: string, labelName: string): SelectOption[] =>
  array.map(item => ({
    value: _.get(item, valueName, item),
    label: _.get(item, labelName, item)
  }));

export const loadBusinessLineOptions = (
  name: string,
  searchValue: string,
  responseName: string
): Promise<SelectOption[]> =>
  api_getBusinessLines({ [name]: searchValue })
    .then(bus_l => bus_l.items)
    .then(bus_l => formatSelectOptions(bus_l as [], 'id', responseName));
