import type { ConceptFromResponse } from 'shared/features/concepts/concepts.types';

import * as errorUtils from 'shared/utils/errors';
import * as conceptConstants from 'shared/features/concepts/concepts.constants';
import * as metaConceptConstants from 'shared/features/metaConcepts/metaConcepts.constants';
import * as conceptsApi from 'shared/features/concepts/concepts.apiV2';

export const useMetaConceptsFiltersAsyncLoaders = () => {
  const handleConceptsFetch = async (inputString: string) => {
    try {
      const response = await conceptsApi.fetchConceptsData({
        [conceptConstants.CONCEPTS_DATA_FETCH_QUERY_PARAMS.MULTI_INPUT]: inputString
      });

      return response.items.map((concepts: ConceptFromResponse) => ({
        value: concepts.id,
        label: concepts.name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  return {
    [metaConceptConstants.META_CONCEPTS_DATA_FETCH_QUERY_PARAMS.CONCEPTS]: handleConceptsFetch
  };
};
