import _ from 'lodash';
import moment from 'moment';
import {
  BusinessLineDetails,
  BusinessLineDetailsNormalized,
  BusinessLineError
} from './BusinessLineDetails.types';
import type * as businessLineApiTypes from 'shared/features/businessLines/businessLines.api.types';
import type {
  SetDifferencePropsCheckFunctionReturn,
  SetDifferencePropsPath,
  SetDifferencePropsValue
} from './BusinessLineDetails.types';
import * as constants from './BusinessLineDetails.constants';
import { DATE_TIME_FORMAT } from 'admin/constants/projectSettings';
// ---------------- GET Concept Info ----------------

const formatTime = (date: string) => (date ? moment(date).format(DATE_TIME_FORMAT) : 'N/A');

// convert DB concept info to screen concept details
export const fromRawInfoToDetails = (
  business_line: businessLineApiTypes.BusinessLineFromResponseV2
): BusinessLineDetailsNormalized =>
  ({
    [constants.BUSINESS_LINE_ATTRIBUTES.name]: business_line?.name ?? '',
    [constants.BUSINESS_LINE_ATTRIBUTES.id]: business_line?.id ?? 'new',
    [constants.BUSINESS_LINE_ATTRIBUTES.description]: business_line?.description ?? '',
    [constants.BUSINESS_LINE_ATTRIBUTES.isActive]: business_line?.is_active ?? false,
    [constants.BUSINESS_LINE_ATTRIBUTES.createdAt]: formatTime(business_line?.created_at ?? ''),
    [constants.BUSINESS_LINE_ATTRIBUTES.updatedAt]: formatTime(business_line?.updated_at ?? ''),
    [constants.BUSINESS_LINE_ATTRIBUTES.isShare]: business_line?.is_shared ?? false
  } as BusinessLineDetailsNormalized);

// ---------------- POST Concept Info ----------------

// // check functions

export const checkRequiredFields = (
  valueNew: SetDifferencePropsValue,
  newInfo: BusinessLineDetails
): SetDifferencePropsCheckFunctionReturn => !valueNew && constants.ERRORS.commonError;

interface SetDifferenceProps {
  newPath: SetDifferencePropsPath;
  oldPath: SetDifferencePropsPath;
  superPath?: string[];
  predefinedValue?: SetDifferencePropsValue;
  checkFunction?: (
    valueNew: SetDifferencePropsValue,
    newInfo: BusinessLineDetails
  ) => SetDifferencePropsCheckFunctionReturn;
}
// function that pre-formats details for API

export const fromDetailsToRawInfo = (
  newInfo: BusinessLineDetails,
  oldInfo: businessLineApiTypes.BusinessLineFromResponseV2
): {
  businessLineDetails: BusinessLineDetails;
  errors: BusinessLineError[];
} => {
  const businessLineDetails: businessLineApiTypes.BusinessLineFromResponseV2 = {
    id: '',
    name: '',
    description: '',
    created_at: '',
    is_active: false,
    is_shared: false,
    updated_at: ''
  };

  const errors: BusinessLineError[] = [];

  const setDifference = ({
    /*
      setDifference - function to check difference between old and edit data
        if there is some difference files send to BE for save

      newPath (required) - array
        - get new data
        - path in crawlDetails (check CRAWL_DETAILS const)
      oldPath (required) - array
        - get old data for check
        - path in original data from BE
        - used like path for set (show the right place)
      superPath (optional) - array
        - path to set data for request in special place
      predefinedValue (optional) - value (string, number, bool, array, object)
        - takes like new data (helps if data have special format or addition)
      checkFunction (optional) - function (return string)
        - function to check that new variable is correct
        - should get the value and return string error or false
    */
    newPath,
    oldPath,
    superPath,
    predefinedValue,
    checkFunction
  }: SetDifferenceProps) => {
    let valueNew;
    if (Boolean(predefinedValue)) valueNew = predefinedValue;
    else valueNew = predefinedValue || _.get(newInfo, newPath, null);

    const valueOld = _.get(oldInfo, oldPath, null);
    if (!_.isEqual(valueNew, valueOld)) {
      _.set(businessLineDetails, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew, newInfo);
        if (result) errors.push({ text: result, title: String(newPath[0]) });
      }
    }
  };

  setDifference({
    newPath: [constants.BUSINESS_LINE_ATTRIBUTES.name],
    oldPath: ['name'],
    checkFunction: checkRequiredFields
  });

  setDifference({
    newPath: [constants.BUSINESS_LINE_ATTRIBUTES.description],
    oldPath: ['description']
  });
  setDifference({
    newPath: [constants.BUSINESS_LINE_ATTRIBUTES.isActive],
    oldPath: ['is_active']
  });

  return { businessLineDetails, errors };
};
