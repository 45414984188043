import type { BusinessLineDetailsHandlers } from './useBusinessLineDetailsHandlers.types';
import { useEffect } from 'react';

export const useBusinessLineDetailsLifecycle = ({
  onCalculateBusinessLineDetails
}: {
  onCalculateBusinessLineDetails: BusinessLineDetailsHandlers['handleCalculateBusinessLine'];
}): void => {
  useEffect(onCalculateBusinessLineDetails, [onCalculateBusinessLineDetails]);
};
